import { EPath, EQuery } from "./EPath";


export class ERequest
{
    private _url: string;
    private _options: RequestInit = {};

    public constructor(path: EQuery | EPath)
    {
        this._url = path.toString();
    }

    public method(method: string): ERequest
    {
        this._options.method = method;
        return this;
    }

    public body(body: any, contentType: string): ERequest
    {
        this._options.headers = Object.assign(this._options.headers ?? {}, {'Content-Type': contentType});
        this._options.body = body;
        console.log("body", this._options.headers, this._options.body);
        return this;
    }

    public bodyJSON(body: any): ERequest
    {
        return this.body(JSON.stringify(body), 'application/json');
    }

    public exec(): Promise<Response>
    {
        return fetch(this._url, this._options);
    }
}

export function handleRequest(p: Promise<Response>, success: (value: any) => void, error?: (error: Response, body: any) => void)
{
    const dispatchError = (resp: Response) =>
    {
        const contentType = resp.headers.get('Content-Type');
        if (contentType?.startsWith("text/plain"))
        {
            resp.text().then
            (
                (y) =>
                {
                    error?.(resp, y);
                },
                (n) =>
                {
                    error?.(resp, undefined);
                }
            );
        }
        else
        {
            resp.json().then
            (
                (y) =>
                {
                    error?.(resp, y);
                },
                (n) =>
                {
                    error?.(resp, undefined);
                }
            );
        }
    };
    p.then
    (
        (y) =>
        {  
            if (y.ok)
            {
                success(y); // TODO
            }
            else
            {
                dispatchError(y);
            }
        },
        (n) =>
        {
            dispatchError(n);
        }
    );
}

export function handleRequestTyped<T>(p: Promise<Response>, success: (value: T) => void, error?: (error: Response, body: any) => void)
{
    return handleRequest(p, 
        (y) =>
        {
            // TODO: errors here
            y.json().then((j: any) => success(j as any as T) );
        },
        error);
}
import { Box, Typography } from '@mui/material';

interface SwitchableProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
  
export function Switchable(props: SwitchableProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  
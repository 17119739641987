import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import './shared/styles/global-styles.css';
import {AdminApp} from './admin/AdminApp';
import {ClientApp} from './client/ClientApp';
import { auth } from './shared/services/Auth.service';
import { Button, createStyles, createTheme, ThemeProvider } from '@mui/material';
import { TestApp } from './test/TestApp';
import "@fontsource/pt-sans";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// TODO: theme doesn't belong here
const theme = createTheme({
  components: {
    MuiFormControl: {
      defaultProps: {
        margin: 'dense'
        
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained'
      }
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true
      }
    }
  },
  typography: {
    button: {
      textTransform: "uppercase"
    }
  },
  palette: {
    primary: {
      main: '#5593B1'
    },
    secondary: {
      main: '#5593B1'
    }

  }
});

// Check if we're showing the admin or test app
const ADMIN_PREFIX = "/admin";
const TEST_PREFIX = "/test";
const isAdmin = window.location.pathname.startsWith(ADMIN_PREFIX);
auth.init(isAdmin ? "/api/admin/auth" : "/api/user");
if (isAdmin)
{
  console.log("isAdmin", window.location.pathname);
  auth.getUser().then((u) => {
    root.render(
      <BrowserRouter basename={baseUrl + ADMIN_PREFIX}>
        <ThemeProvider theme={theme}>
          <AdminApp />
          </ThemeProvider>
      </BrowserRouter>
  );})
}
else if (window.location.pathname.startsWith(TEST_PREFIX))
{
  console.log("isTest", window.location.pathname);
  root.render(
  <BrowserRouter basename={baseUrl}>
    <ThemeProvider theme={theme}>
      <TestApp />
    </ThemeProvider>
  </BrowserRouter>
  );
}
else
{
  console.log("isClient", window.location.pathname);
  auth.getUser().then((u) => {
    root.render(
    <BrowserRouter basename={baseUrl}>
      <ThemeProvider theme={theme}>
        <ClientApp />
      </ThemeProvider>
    </BrowserRouter>
  );})
}
import { CRUDFieldContext, CRUDReducerField, FeedbackStatus, FieldFeedback } from "./Reducer";
import { FieldValidator, Validation, ValidationError, ValidationSuccess, ValidationUnchecked } from "./Validator";
import { FieldComponent } from "./FieldComponent";
import {
    CheckBox as BooleanTrueIcon,
    CheckBoxOutlineBlank as BooleanFalseIcon
}  from '@mui/icons-material';

export enum FieldFormMode
{
    Default,
    ReadOnly,
    Exclude
}

export type ValueChangeHandler = (field: CRUDField, value: any, context: CRUDFieldContext) => void;
export type ControlConstructor = (field: CRUDField, value: any, context: CRUDFieldContext) => React.ReactNode;

export type CRUDFieldOptions =
{
    display?: string;
    placeholder?: string;
    mode?: FieldFormMode;
    initValue?: () => any;
    type?: string | undefined; 
    inputOptions?: any | undefined;
    validator?: FieldValidator | undefined;
    onValueChange?: ValueChangeHandler | undefined;
    enumType?: any;
    readOnly?: boolean;
    enabled?: boolean;
    submit?: boolean;
    table?: boolean;
    control?: ControlConstructor;
}

export class CRUDField
{
    private _name: string;
    private _options: CRUDFieldOptions;

    public constructor(name: string, options: CRUDFieldOptions)
    {
        this._name = name;
        this._options = options;
    }

    public withMergedOptions(options: CRUDFieldOptions): CRUDField
    {
        return new CRUDField(this._name, Object.assign({...this._options}, options));
    }

    public get fieldName(): string  {return this._name;}
    public get formField(): boolean {return (this._options.mode ?? FieldFormMode.Default) !== FieldFormMode.Exclude ;}
    public get showField(): boolean {return this.formField && !!this._options.display;}
    public get displayName(): string {return this._options.display as string;}
    public get initValue(): any {return this._options.initValue ? this._options.initValue() : '' }
    public get options(): CRUDFieldOptions {return this._options;}

    public get isEnum(): boolean {return !!this.options.enumType;}
    public get isArray(): boolean {return this.options.type?.endsWith("[]") == true;}

    public createInput<T>(context: CRUDFieldContext, value: CRUDReducerField, readOnly: boolean)
    {
        if (this.showField)
        {
            return <FieldComponent 
                        key={this.fieldName + '-' + this._options.enabled} 
                        field={this}
                        context={context} 
                        value={value}
                        readOnly={readOnly || (this._options.readOnly ?? false)}
                        enabled={this._options.enabled ?? true}
                    />;
        }
        else
        {
            return <input 
                type="hidden"
                key={this.fieldName}
                name={this.fieldName}
                id={this.fieldName}
                value={value.get()}
                {...this._options.inputOptions}
            />;
        }
    }

    public createSubmitValue(value: any)
    {
        if (this.isEnum && this.isArray)
        {
            return (value as number[]).reduce((sum, current) => sum + current, 0);
        }
        return value;
    }

    public createLabel(obj: any)
    {
        let parts = this.fieldName.split(".");
        let value = obj;
        for (let i = 0; i < parts.length && value; ++i)
        {
            value = value[parts[i]];
        }
        
        if (this._options.type === "image")
        {
            return (<span className="value-label value-label-image"><img src={value}/></span>)
        }
        else if (this._options.type === "boolean")
        {
            return (<span className="value-label">{value ? <BooleanTrueIcon/> : <BooleanFalseIcon/>}</span>)
        }
        else if (this._options.type === "double[]")
        {
            return (<span className="value-label">{value?.sort((a: number, b: number) => a - b)?.map((num: number) => num.toFixed(1))?.join("; ")}</span>)
        }
        else if (this.isEnum)
        {
            if (this.isArray)
            {
                let mapped = this.ensureFieldMapped(value);
                value = mapped.map((key: number) => this._options.enumType[key]).join("; ");
            }
            else
            {
                value = this._options.enumType[this.ensureFieldMapped(value)]
            }
            return (<span className="value-label">{value}</span>)
        }
        else
        {
            return (<span className="value-label">{value}</span>)
        }
    }

    public ensureFieldMapped(value: any)
    {
        if (this.isEnum)
        {
            return this.ensureEnumValueNumeric(value);
        }
        else if (this._options.type === 'boolean')
        {
            return value === true || value === 'true';
        }
        else if(this.isArray)
        {
            // Convert any false to null, means empty array
            if (!value)
                return null;
        }
        return value;
    }

    private ensureEnumValueNumeric(value: any)
    {
        if (this.isEnum)
        {
            if (this.isArray)
            {
                if (!value)
                {
                    value = [];
                }
                else if (typeof(value) === "object")
                {
                    value = value.sort();
                }
                else if (typeof(value) === "number")
                {
                    let n = value as number;
                    value = [];
                    Object.keys(this._options.enumType).forEach((key: string) =>
                    {
                        if ((parseInt(key) & n) != 0)
                        {
                            value.push(parseInt(key));
                        }
                    });
                }
                // Filter out invalid values
                // TODO: better handling of this
                value = value.filter((key: number) => Object.keys(this._options.enumType).indexOf(key.toString()) >= 0 );
            }
            else
            {
                // Select first enum value if no value specified
                // TODO: customisable default
                if (!(value in this._options.enumType))
                {
                    value = Object.keys(this._options.enumType)[0];
                }
                // Convert to number
                if (!isNaN(parseInt(value)))
                {
                    value = parseInt(value);
                }
            }
        }
        return value;
    }
    
    public validate(value: any, context: CRUDFieldContext, isInProgress: boolean, feedback?: FieldFeedback | undefined): Validation
    {
        // A disabled field is always correct
        if (this._options.enabled === false)
            return ValidationSuccess;

            // Feedback and validation
        if (feedback?.status == FeedbackStatus.Duplicate)
        {
            return new ValidationError(`${this.displayName} already exists`);
        }
        if (feedback?.status == FeedbackStatus.Explicit)
        {
            return new ValidationError(feedback.message);
        }


        if (!this._options.validator)
        {
            if (feedback)
                return new ValidationError("Value is incorrect"); // TODO: better message
            return ValidationUnchecked;
        }

        return this._options.validator.validate(this, value, context, isInProgress, feedback);
    }
}
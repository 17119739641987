import { DialogActions,Stack } from '@mui/material';
import { createPage, PageContent } from "../../shared/components/PageBase";
import { useCRUDReducer } from "../../shared/components/crud/Reducer";
import { CRUDForm, CRUDService, CRUDType } from "../../shared/components/crud/index.d";
import { FormButtons } from "../../shared/components/FormButtons";
import { EPath } from "../../shared/util/EPath";
import { handleRequest } from "../../shared/util/ERequest";
import { FieldNewPassword, FieldNewPasswordConfirm } from '../../shared/Fields';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../shared/components/Auth';
import { FieldLogin } from '../../shared/model/EntityBase';
import { FieldValidatorNotEmpty } from '../../shared/components/crud/Validator';

interface CreateAdminAccountFormData
{
    displayName: string;
    login: string;
    newPassword1: string;
    newPassword2: string;
}

const CreateAdminAccountFormData_CT = new CRUDType<CreateAdminAccountFormData>()
    .add("displayName", {
        placeholder: "Full name",
        display: "Name",
        validator: new FieldValidatorNotEmpty()
    })
    .add("login", FieldLogin)
    .add("newPassword1", FieldNewPassword)
    .add("newPassword2", FieldNewPasswordConfirm)
;

// TODO: CT type
// TODO: path constant
const AdminSignUp_CRUDService = new CRUDService(CreateAdminAccountFormData_CT, new EPath("/api/admin/admin"));

export const AdminSignUp = createPage(() => {

    let navigate = useNavigate();
    let token = new URLSearchParams(document.location.search).get('token');
    if (!token)
    {
        navigate('/');
    }
    let auth = useAuth();

    const reducer = useCRUDReducer(CreateAdminAccountFormData_CT);
  
    const submit = () =>
    {
      let v = reducer.values;
      console.log("submit", v);

      handleRequest(AdminSignUp_CRUDService.postToFunction("signup", {
        displayName: v.displayName,
        login: v.login,
        token: token,
        password: v.newPassword1
      }),
        (success) =>
        {
            console.log("confirm success", success);
            auth.refreshUser().then
            (
                (x) => 
                {
                    navigate("/", { replace: true });
                    document.location.reload();
                }
            );
        },
        (err, json) =>
        {
            console.log("confirm error", err, json);
            reducer.onSubmitError(json);
        }
      );
    };

    return <PageContent>
            To complete your adminstrator account, please complete the form below.

            <Stack>
                <CRUDForm<CreateAdminAccountFormData> 
                    type={CreateAdminAccountFormData_CT}
                    values={reducer}
                    submit={submit}
                />

                <DialogActions>
                    <FormButtons
                        values={reducer}
                        submit={submit}
                        submitLabel="Create account"
                    />
                </DialogActions>
            </Stack>
    </PageContent>
    ;
}, "Confirm Sign up")

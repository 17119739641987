import { Fragment } from "react";
import { createPage } from "../../shared/components/PageBase";

export const ESPLaunch = createPage(() => {

    return <Fragment>
        <div>
        Launching the ESP application.
        </div>

        <div>
        If it fails to open, please <a href="">click here.</a>
        </div>
    </Fragment>;
}, "Launch ESP")

import { CRUDType } from "../../../shared/components/crud/index.d";
import { EntityBase, Group, GroupType, User, UserBase } from "../../model/Clients";
import { v4 as uuidv4 } from 'uuid';
import { FieldFormMode } from "../../../shared/components/crud/Field";
import { FieldValidatorNotEmpty } from "../../../shared/components/crud/Validator";
import { FieldLogin } from "../../../shared/model/EntityBase";
import { FieldEmail } from "../../../shared/Fields";

export const EntityBase_CT = new CRUDType<EntityBase>()
    .add("id", {
        initValue: uuidv4
    })
    .add("displayName", {
        display: "Name",
        validator: new FieldValidatorNotEmpty()
    })
;

export const UserBase_CT = new CRUDType<UserBase>(EntityBase_CT)
    .merge("displayName", {
        placeholder: "The user's full name"
    })
    .add("login", FieldLogin)
    .add("email", FieldEmail)
;

export const User_CT = new CRUDType<User>(UserBase_CT)
    .add("practiceName", {
        display: "Practice",
        mode: FieldFormMode.Exclude
    })
;

export const Group_CT = new CRUDType<Group>(EntityBase_CT)
    .merge("displayName", {
        placeholder: "Name of the group"
    })
    .add("type", {
        display: 'Group type',
        placeholder: 'The type of the group',
        enumType: GroupType
    })
;

import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CRUDEditor, CRUDService, CRUDType } from "./index.d";
import { useStateAsync } from "../../util/StateHelpers";
import { alpha } from '@mui/material/styles';

import {
    Box,
    Checkbox,
    Dialog,
    DialogTitle,
    DialogContent,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    Toolbar,
    Tooltip,
    Typography,
    TextField
  } from '@mui/material';

import {
    Add as AddIcon,
    Clear as ClearIcon,
    Delete as DeleteIcon,
    Refresh as RefreshIcon
}  from '@mui/icons-material';

import { CRUDField } from "./Field";
import { useConfirm } from 'material-ui-confirm';
import { Cursor, EmptyCursorData, Sort } from "./Cursor";
import { useDialogs } from "../../Dialogs";
import { TableAction, TableHeader } from "../TableHeader";

interface CRUDTableProperties<T>
{
    title: string;
    type: CRUDType<T>;
    service: CRUDService<T>;
    createTitle?: string;
    createAction?: () => void;
    selectAction?: (item: any, id: any) => void;
    sort?: Sort;
    readOnly?: boolean;
    selectable?: boolean;
    classNamePrefix?: string;
}

export const CRUDTable = <T extends unknown>(props : CRUDTableProperties<T>) => 
{
    let {title, type, service} = props;
    const navigate = useNavigate();

    // TODO: custom https://github.com/smashboy/react-mui-confirm/blob/main/src/ConfirmDialog.tsx
    const confirm = useConfirm();
    const dialogs = useDialogs();

    // State
    let [cursor, setCursor] = useState<Cursor>({
        sort: props.sort ?? {field: undefined, ascending: true},
        paging: {size: 15, index: 0} // TODO: guess page size froms height
    });
    const setSort = (sort: Sort) =>
    {
        setCursor({...cursor, sort: sort});
    };
    let [data, dataRefresh] = useStateAsync(EmptyCursorData, (cursor) => service.list(cursor), cursor);

    const switchSort = (field: CRUDField) =>
    {
        if (cursor.sort.field == field)
        {
            setSort({field: cursor.sort.field, ascending: !cursor.sort.ascending});
        }
        else
        {
            setSort({field: field, ascending: true});
        }
    };

    const pageChange = (event: unknown, newPage: number) => {
        setCursor({...cursor, paging: {...cursor.paging, index: newPage * cursor.paging.size}});
    };
    const rowsPerPageChange = (event: any) => {
        setCursor({...cursor, paging: {...cursor.paging, size: event.target.value}});
    };

    const setFilter = (text: string | null) =>
    {
        let filter = undefined;
        if (text)
        {
            filter = {filter: text};
        }
        setCursor({...cursor, filter: filter});
    };

    // Dialog mode
    let [create, setCreate] = useState(false);

    // Selection
    const selectNavigate = (item: any, itemId: any) =>
    {
        navigate(`${itemId}`);
    };
    const selectItem = props.selectable === false ? (item: any, itemId: any) => {} :(props.selectAction ?? selectNavigate);

    const [selected, setSelected] = useState<readonly string[]>([]);
    const selectionModel = 
    {
        clear: (): void =>
        {
            setSelected([]);
        },

        isEmpty: (): boolean =>
        {
            return selected.length == 0;
        },

        count: (): number =>
        {
            return selected.length;
        },

        hasAll: (): boolean => 
        {
            return selected.length == cursor.paging.size;
        },

        hasSome: (): boolean => 
        {
            return selected.length > 0 && selected.length < cursor.paging.size;
        },

        isSelected: (id: string): boolean =>
        {
            return selected.indexOf(id) >= 0;
        },

        toggle: (item: any, id: string): void =>
        {
            const selectedIndex = selected.indexOf(id);
            let newSelected: readonly string[] = [];
        
            if (selectedIndex === -1) {
              newSelected = newSelected.concat(selected, id);
            } else if (selectedIndex === 0) {
              newSelected = newSelected.concat(selected.slice(1));
            } else if (selectedIndex === selected.length - 1) {
              newSelected = newSelected.concat(selected.slice(0, -1));
            } else if (selectedIndex > 0) {
              newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
              );
            }
        
            setSelected(newSelected);
        },

        selectAll: (event: React.ChangeEvent<HTMLInputElement>) => 
        {
            if (event.target.checked) 
            {
                console.log("selecting all", event.target.checked, event.target, event);
                setSelected(data.data.map(i => type.id(i)));
                return;
            }
            console.log("selecting none");
            setSelected([]);
        },

        ids: () =>
        {
            return selected;
        }
    };

    const handleDelete = () =>
    {
        // TODO: error handling
        confirm({ description: 'Delete selected items?' })
            .then(() => { 
                service.delete(selectionModel.ids())
                    .then((responses) => 
                    {
                        for(let i in responses)
                        {
                            let r = responses[i];
                            if (!r.ok)
                            {
                                r.text().then((msg) =>
                                {
                                    console.info("error", msg);
                                    dialogs.info({title: "Error", content: msg});
                                });
                            }
                        }
                        selectionModel.clear();
                        dataRefresh();
                    });
            })
        ;
    };

    const handleRefresh = () =>
    {
        dataRefresh();
    };

    const filter = 
    {
        clearFilter: (): void =>
        {
            console.info("FILTER0", cursor.filter?.filter);
            setFilter("");
        },

        handleInputChange: (event: any): void =>
        {
            console.info("FILTER2", event.target.value, cursor.filter?.filter);
            setFilter(event.target.value);
        }
    };

    const actions_read = [
        {
            needsSelection: undefined, 
            element: 
                <Tooltip title="Refresh" key="refresh">
                    <IconButton
                        onClick={handleRefresh}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Tooltip>
        },
        {
            needsSelection: undefined,
            element: <TextField 
                label="Filter"
                sx={{ width: 300 }}
                key="filter"
                onChange={filter.handleInputChange}
                value={cursor.filter?.filter ?? ""}
                className="CRUDFilter"
                InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => filter.clearFilter()}>
                        <ClearIcon />
                      </IconButton>
                    )
                  }}
            />
        }
    ] as TableAction[];

    const actions_write = [
        {
            needsSelection: true, 
            element: 
                <Tooltip title="Delete" key="delete">
                    <IconButton
                        onClick={handleDelete}
                    >
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
        },
        {
            needsSelection: false, 
            element: 
                <Tooltip title={props.createTitle ?? "Create"} key="create">
                    <IconButton
                        onClick={() => props.createAction ? props.createAction() : setCreate(true)}
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
        }
    ] as TableAction[];

    const actions = props.readOnly ? (actions_read) : (actions_read.concat(actions_write));

    // See https://mui.com/material-ui/react-table/
    return <Fragment>
        <Box sx={{ width: '100%' }}>
            <Paper>
                <TableHeader 
                    title={title}
                    selection={selectionModel}
                    actions={actions}
                />
                <TableContainer>
                    <Table
                        size="medium"
                    >
                        <TableHead>
                            <TableRow>
                                {
                                    props.readOnly ? (<Fragment/>) : (
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                indeterminate={selectionModel.hasSome()}
                                                checked={selectionModel.hasAll()}
                                                onChange={selectionModel.selectAll}
                                            />
                                        </TableCell>
                                    )

                                }

                                {
                                    type.tableFields.map((field) => 
                                    <TableCell 
                                        key={field.fieldName} 
                                        sortDirection={false}
                                    >
                                        <TableSortLabel
                                            active={cursor.sort.field == field}
                                            direction={cursor.sort.field == field ? (cursor.sort.ascending ? 'asc' : 'desc') : 'asc'}
                                            onClick={() => switchSort(field)}
                                        >
                                            {field.displayName}
                                        </TableSortLabel>
                                    </TableCell>)
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data.data.map((item) => {
                                    const itemId = type.id(item);
                                    const isItemSelected = selectionModel.isSelected(itemId);

                                    return (
                                    <TableRow 
                                        key={itemId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        selected={isItemSelected}
                                    >
                                        {props.readOnly ? (<Fragment/>) : (
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    checked={isItemSelected}
                                                    onClick={(event) => {selectionModel.toggle(item, itemId); event.preventDefault();}}
                                                />
                                            </TableCell>
                                        )}
                                        
                                        {
                                            type.tableFields.map((field) => <TableCell key={"" + itemId + "-" + field.fieldName}
                                                onClick={() => selectItem(item, itemId)}
                                                className={props.classNamePrefix ? (props.classNamePrefix + field.fieldName) : undefined}
                                            >
                                                {field.createLabel(item)}
                                            </TableCell>)
                                        }

                                    </TableRow>
                                    )}
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={data.matching}
                    rowsPerPage={cursor.paging.size}
                    rowsPerPageOptions={[5, 10, 15, 20, 25]}
                    onRowsPerPageChange={rowsPerPageChange}
                    page={(cursor.paging.index ?? 0) / cursor.paging.size}
                    onPageChange={pageChange}
                />
            </Paper>
        </Box>

        <Dialog onClose={()=>setCreate(false)} open={create}>
            <DialogTitle>{props.createTitle ?? "Create"}</DialogTitle>
            <DialogContent>
                <CRUDEditor<T> 
                    type={type}
                    service={service}
                    onClose={()=>setCreate(false)}
                    onSuccess={dataRefresh}
                />
            </DialogContent>
        </Dialog>
    </Fragment>
}


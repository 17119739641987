

interface GroupProperties
{
    label: string;
    children: React.ReactNode
}
export const Group = ({label, children} : GroupProperties) => 
{
    return (<div className="group">
        <div className="groupLabel">{label}</div>
        <div className="groupChildren">{children}</div>
        
        </div>
    );
}
import { DialogActions,Stack } from '@mui/material';
import { createPage, PageContent } from "../../../shared/components/PageBase";
import { useCRUDReducer } from "../../../shared/components/crud/Reducer";
import { CRUDForm, CRUDService, CRUDType } from "../../../shared/components/crud/index.d";
import { FormButtons } from "../../../shared/components/FormButtons";
import { EPath } from "../../../shared/util/EPath";
import { handleRequest } from "../../../shared/util/ERequest";
import { useState } from "react";
import { Switchable } from '../../../shared/components/Switchable';
import { FieldEmail } from '../../../shared/Fields';
import EagletEmailIcon from '../../../shared/images/Icon_Mail.png';

export interface UserInvite
{
    readonly email: string;
};

export const UserInvite_CT = new CRUDType<UserInvite>()
    .add("email", FieldEmail)
;
// TODO: CT type
// TODO: path constant
const User_CRUDService = new CRUDService(UserInvite_CT, new EPath("/api/user"));

export const RequestSignUp = createPage(() => {

    const reducer = useCRUDReducer(UserInvite_CT);
    const [showConfirmation, setShowConfirmation] = useState(false);
  
    const submit = () =>
    {
      let v = reducer.values;
      console.log("submit", v);

      handleRequest(User_CRUDService.postToFunction('signup/request', v),
        (success) =>
        {
            console.log("signup success", success);
            setShowConfirmation(true);
        },
        (err, json) =>
        {
            console.log("signup error", err, json);
            reducer.onSubmitError(json);
        }
      );
    };

    return <PageContent>
        <Switchable value={showConfirmation} index={false}>
            <h1>Eye Surface Profiler</h1>
            <p>To sign up for Eye Surface Profiler,<br/>
            please enter your email address below:</p>
            <Stack>
                <CRUDForm<UserInvite> 
                    type={UserInvite_CT}
                    values={reducer}
                    submit={submit}
                />

                <DialogActions>
                    <FormButtons
                        values={reducer}
                        submit={submit}
                        submitLabel='Sign Up'
                    />
                </DialogActions>
            </Stack>
        </Switchable>
        <Switchable value={showConfirmation} index={true}>
            <h1>Thank you!</h1>
            <p>Thank you for signing up to the Eye Surface Profiler.
            Please check your email for a confirmation message to complete your sign up.</p>
            <img className='icon' src={EagletEmailIcon}/>
        </Switchable>
    </PageContent>
    ;
}, "Sign up")

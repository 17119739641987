import {
    PhotoCamera as CameraIcon,
    Delete as DeleteIcon
}  from '@mui/icons-material';
import { ChangeEvent, Fragment, useEffect, useState } from "react";

type OnValueChangeEventHandler = (value: string | null) => void;

interface ImageControlProperties
{
    label: string;
    name: string;
    id: string;
    value?: any;

    onValueChange?: OnValueChangeEventHandler;
    readOnly?: boolean;
}

// TODO: in lin
async function blobToBase64(blob: Blob): Promise<string> {
    return await new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

export const ImageControl = (props : ImageControlProperties) => 
{
    const [currentUrl, setCurrentUrl] = useState(props.value);
    const handleFilePicker = async (e: ChangeEvent<HTMLInputElement>) => {
        const { files } = e.target
    
        if (files != null && files.length > 0) 
        {
            let blobUrl = URL.createObjectURL(files[0]);
            setCurrentUrl(blobUrl);
          
            if (props.onValueChange)
            {
                let value = await blobToBase64(await fetch(blobUrl).then(r => r.blob()));
                props.onValueChange(value);
            }
        }
      }
    
      const handleDeleteImage = (): void => 
      {
        setCurrentUrl(null)
        props.onValueChange?.(null);
      }

    return (<div className="image-control">
        <label
         className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-standard MuiFormLabel-colorPrimary MuiFormLabel-filled css-vmhxk-MuiFormLabel-root-MuiInputLabel-root" 
         data-shrink="true" 
         htmlFor={props.id}
         id={props.id + '-label'}
        >
            {props.label}
        </label>
            <div className='uploader__container'>
                <div className='uploader__placeholder'>
                    {props.readOnly ? (<Fragment/>) : (
                        <div className='uploader__btn_wrapper'>
                            <div className='uploader__btn'  onClick={() => handleDeleteImage()}>
                                <DeleteIcon/>
                            </div>
                        </div>
                    )}
                    
                    {props.readOnly ? (<Fragment/>) : (
                        <label id='file_uploader' className='uploader__file_input_label'>
                            <CameraIcon/>

                            <input
                                className='uploader__file_input'
                                key={currentUrl}
                                type='file'
                                name='upload'
                                onChange={(e) => handleFilePicker(e)}
                                accept='image/*'
                                id='file_uploader'
                            />
                        </label>
                    )}

                    <img
                        className='uploader__file'
                        src={currentUrl}
                        loading='lazy'
                    />
                </div>
            </div>
        </div>
    );
}
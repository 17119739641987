import { Button } from "@mui/material";
import { Fragment } from "react";
import { Validation } from "./Validator";


interface CRUDButtonsProperties
{
    create: boolean;
    submitTitle?: string;
    validity: Validation;
    hasChanges?: boolean;
    submit: () => void;
    cancel?: () => void;
    reset?: () => void;
}
export const CRUDButtons = (props: CRUDButtonsProperties) => 
{
    return (<Fragment>
        {
            props.cancel && (
                <Button
                    color='secondary'
                    onClick={props.cancel}
                >
                    Cancel
                </Button>
            )
        }

        {
            props.reset && (
                <Button
                    color='secondary'
                    onClick={props.reset}
                    disabled={props.hasChanges === false}
                >
                    Reset
                </Button>
            )
        }

        <Button
            color='primary'
            onClick={props.submit}
            disabled={!props.validity.isAcceptable || (!props.create && props.hasChanges === false)}
        >
            {props.submitTitle ?? (props.create ? "Create" : "Update")}
        </Button>
        </Fragment>
    );
}
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../shared/components/Auth";
import { LinkButton } from "../../shared/components/LinkButton";
import { createPage } from "../../shared/components/PageBase";

export const ClientLanding = createPage(() => {
    let auth = useAuth();
    let navigate = useNavigate();
    if (auth.user)
        navigate("/home", {replace: true});

    
    return <div id="landing">
        <p>Welcome to</p>
        <h1>Eye Surface Profiler</h1>

        <p>If you already have an account:</p> <LinkButton to="/login">log in here</LinkButton>

        <p>If you do not have an account yet:</p> <LinkButton to="/signup">sign up here</LinkButton>

    </div>;
}, "Home")

import { Routes } from 'react-router-dom';
import { AuthProvider } from '../shared/components/Auth';
import { ConfirmProvider } from 'material-ui-confirm';
import { mapRoutes, RoutableEntry } from '../shared/util/Routing';
import { TestPage } from './TestPage';


export const TEST_APP_ROUTES: readonly RoutableEntry[] =
[
    {
        path: "/test",
        element: TestPage,
        auth: false,
        children: [{
            path: ':id',
            element: TestPage
        }]

    }
];


export function TestApp() {
    return (
        <ConfirmProvider><AuthProvider>
            <Routes>
                {
                    mapRoutes(TEST_APP_ROUTES)
                }
            </Routes>
        </AuthProvider></ConfirmProvider>
    );
}

import {AdminUserPage, AdminUsersPage} from './pages/admin/AdminUsers';
import {ClientUser, ClientUsers} from './pages/client/ClientUsers';
import {ClientCompanies, ClientCompany, ClientSuppliers, ClientSupplier} from './pages/client/ClientGroups';
import { RoutableEntry } from '../shared/util/Routing';
import { Login } from '../shared/pages/Login';
import { Home } from './pages/Home';
import { MyAccountPage } from '../shared/pages/MyAccountPage';
import { AdminSignUp } from './pages/AdminSignUp';
import { ResetPasswordAdmin } from '../shared/pages/ResetPassword';
import { DebugSettings } from './pages/system/DebugSettings';
import { Analytics } from './pages/analytics/Analytics';
import { DirectConnectLensSetPublished, DirectConnectSupplierPublished, DirectConnectSuppliersPublished } from './pages/directconnect/DirectConnectSuppliersPublished';
import { DirectConnectLensSetEdit, DirectConnectSupplierEdit, DirectConnectSuppliersEdit } from './pages/directconnect/DirectConnectSuppliersEdit';
import { DirectConnectAnalytics } from './pages/directconnect/DirectConnectAnalytics';
import { DirectConnectTestSupplier } from './pages/directconnect/TestSupplier';
import { DebugLogs, DebugStartupLogs } from './pages/system/DebugLogs';
import { DebugServices } from './pages/system/DebugServices';
import { DebugCache } from './pages/system/DebugCache';
import { DebugSystem } from './pages/system/DebugSystem';

export const ADMIN_APP_ROUTES: readonly RoutableEntry[] =
[
    {
        path: "/",
        element: Home,
    },
    {
        path: "/login",
        element: Login,
        auth: false
    },
    {
        path: "/login/reset/confirm",
        element: ResetPasswordAdmin,
        auth: false
    },
    {
        path: "/signup",
        element: AdminSignUp,
        auth: false
    },
    {
        path: "/admin/admin-users",
        element: AdminUsersPage,
        menu: 'primary',
        children: [{
            path: ':id',
            element: AdminUserPage
        }]
    },
    {
        path: "/client",
        group: "Clients",
        menu: 'primary'
    },
    {
        path: "/client/users",
        element: ClientUsers,
        menu: 'primary',
        children: [{
            path: ':id',
            element: ClientUser
        }]
    },
    {
        path: "/client/company",
        element: ClientCompanies,
        menu: 'primary',
        children: [{
            path: ':id',
            element: ClientCompany
        }]
    },
    {
        path: "/client/supplier",
        element: ClientSuppliers,
        menu: 'primary',
        children: [{
            path: ':id',
            element: ClientSupplier
        }]
    },
    {
        path: "/directconnect",
        group: "DirectConnect",
        menu: 'primary'
    },
    {
        path: "/directconnect/suppliers/published",
        element: DirectConnectSuppliersPublished,
        menu: 'primary',
        children: [{
            path: ':id',
            element: DirectConnectSupplierPublished,
            children: [{
                path: ':setId',
                element: DirectConnectLensSetPublished
            }]
        }]
    },
    {
        path: "/directconnect/suppliers/edit",
        element: DirectConnectSuppliersEdit,
        menu: 'primary',
        children: [{
            path: ':id',
            element: DirectConnectSupplierEdit,
            children: [{
                path: ':setId',
                element: DirectConnectLensSetEdit
            }]
        }]
    },
    {
        path: "/directconnect/analytics",
        element: DirectConnectAnalytics,
        menu: 'primary'
    },
    {
        path: "/directconnect/test/supplier",
        element: DirectConnectTestSupplier,
        menu: 'primary'
    },
    {
        path: "/analytics",
        element: Analytics,
        menu: 'primary'
    },
    {
        path: "/account",
        element: MyAccountPage,
        menu: 'primary'
    },
    {
        path: "/debug",
        group: "Debug",
        menu: 'primary'
    },
    {
        path: "/debug/logs",
        element: DebugLogs,
        menu: 'primary'
    },
    {
        path: "/debug/startupLogs",
        element: DebugStartupLogs,
        menu: 'primary'
    },
    {
        path: "/debug/system",
        element: DebugSystem,
        menu: 'primary'
    },
    {
        path: "/debug/cache",
        element: DebugCache,
        menu: 'primary'
    },
    {
        path: "/debug/settings",
        element: DebugSettings,
        menu: 'primary'
    },
    {
        path: "/debug/services",
        element: DebugServices,
        menu: 'primary'
    }
];
import { useConfirm } from "material-ui-confirm";
import React, { Fragment } from "react";
import {
    DialogProps as MUIDialogProps,
    ButtonProps,
    DialogTitleProps,
    TextFieldProps,
    DialogContentTextProps,
    DialogActionsProps,
    DialogContentProps,
    LinearProgressProps,
    CircularProgress,
  } from '@mui/material';
  import {
    Button,
    Dialog as MUIDialog,
    DialogActions,
    DialogTitle,
    DialogContent
  } from '@mui/material'

interface BaseDialogOptions
{
    title: string;
    content: React.ReactNode;

    cancelButtonText?: string | undefined;
    confirmButtonText?: string | undefined;

    dialogProps?: Omit<MUIDialogProps, "open" | "onClose">;
    dialogTitleProps?: DialogTitleProps;
    dialogContentProps?: DialogContentProps;
    dialogContentTextProps?: DialogContentTextProps;
    dialogActionsProps?: DialogActionsProps;
    confirmTextFieldProps?: Omit<TextFieldProps, "onChange" | "value">;
    confirmButtonProps?: Omit<ButtonProps, "onClick" | "disabled">;
    cancelButtonProps?: Omit<ButtonProps, "onClick">;
}

export interface ConfirmDialogOptions extends BaseDialogOptions
{

}

export interface MessageDialogOptions extends BaseDialogOptions
{

}


export type DialogProps = {
    show: boolean;
    showCancel: boolean;
    finalOptions: BaseDialogOptions;
    onCancel: () => void;
    onConfirm: () => void;
  };

  
const LoadingButton: React.FC<{
    isLoading?: boolean;
    indicatorColor?: 'primary' | 'secondary' | 'inherit';
  } & ButtonProps> = ({ children, isLoading, disabled, indicatorColor, ...otherProps }) => {
    return (
      <Button disabled={disabled || isLoading === true || false} {...otherProps}>
        {isLoading ? <CircularProgress color={indicatorColor || 'primary'} size={25} /> : children}
      </Button>
    );
  };

  
const defaultGlobalOptions: BaseDialogOptions = {
    title: '',
    content: '',

    confirmButtonText: 'Confirm',
    cancelButtonText: 'Cancel',
    confirmButtonProps: {
      color: 'primary',
    },
    cancelButtonProps: {
      color: 'primary',
      autoFocus: true,
    },
  };

  
export const Dialog: React.FC<DialogProps> = ({
    show,
    onConfirm,
    showCancel,
    onCancel,
    finalOptions
  }) => {
    
    const [loading, setLoading] = React.useState(false);
  
    const close = React.useCallback((handler: () => void) => {
      handler();
    }, []);
  
    return (
      <MUIDialog {...finalOptions.dialogProps} open={show} onClose={() => close(onCancel)}>
        <DialogTitle {...finalOptions.dialogTitleProps}>{finalOptions.title!}</DialogTitle>
        <DialogContent {...finalOptions.dialogContentProps}>
          {finalOptions?.content}
        </DialogContent>
        <DialogActions {...finalOptions.dialogActionsProps}>
            {showCancel && (
                <Button {...finalOptions.cancelButtonProps} onClick={() => close(onCancel)}>
                    {finalOptions?.cancelButtonText || defaultGlobalOptions.cancelButtonText}
                </Button>
            )}
          <LoadingButton
            {...finalOptions.confirmButtonProps}
            isLoading={loading}
            onClick={() => close(onConfirm)}
          >
            {finalOptions?.confirmButtonText || (showCancel ? defaultGlobalOptions.confirmButtonText : 'Close')}
          </LoadingButton>
        </DialogActions>
      </MUIDialog>
    );
  };

interface DialogsContextType {
    confirm: (options: ConfirmDialogOptions) => Promise<void>;
    info: (options: MessageDialogOptions) => Promise<void>; 
    error: (options: MessageDialogOptions) => Promise<void>; 
}

const DialogsContext = React.createContext<DialogsContextType>(null!);
 
export function DialogsProvider({ children }: { children: React.ReactNode }) {
    const [options, setOptions] = React.useState<BaseDialogOptions>({title: '', content: ''});
    const [isSimple, setSimple] = React.useState(false);

    const [promise, setPromise] = React.useState<{
        resolve?: (value?: any) => void;
        reject?: () => void;
      }>({});

    const handleCancel = () =>
    {
        if (isSimple)
        {
            promise?.resolve?.();
        }
        else
        {
            promise?.reject?.();
        }
        setPromise({});
    }

    const handleConfirm = () =>
    {
        promise?.resolve?.();
        setPromise({});
    }
    
    const impl = {
        confirm: React.useCallback((confirmOptions: ConfirmDialogOptions) => {
            setSimple(false);
            return new Promise<void>((resolve, reject) => {
                setOptions(confirmOptions);
                setPromise({ resolve, reject });
            });
          }, []),

        info: React.useCallback((confirmOptions: MessageDialogOptions) => {
            setSimple(true);
            return new Promise<void>((resolve, reject) => {
                setOptions(confirmOptions);
                setPromise({ resolve, reject });
            });
          }, []),
        
          // TODO: icon
        error: React.useCallback((confirmOptions: MessageDialogOptions) => {
            setSimple(true);
            return new Promise<void>((resolve, reject) => {
                setOptions(confirmOptions);
                setPromise({ resolve, reject });
            });
          }, [])

    };


    return (<Fragment>
            <DialogsContext.Provider value={impl}>{children}</DialogsContext.Provider>
            <Dialog
                show={Object.keys(promise).length === 2}
                onCancel={handleCancel}
                onConfirm={handleConfirm}
                finalOptions={options}
                showCancel={!isSimple}
            />
        </Fragment>);
}


export const useDialogs = () => {
    const context = React.useContext(DialogsContext);
  
    if (!context) 
        throw new Error('useDialogs must be used within a DialogsProvider');
  
    return context;
  };
import { Fragment } from "react";
import { createPage, PageHeader } from "../../shared/components/PageBase";
import EagletHomeIcon from '../../shared/images/Icon_Home.png';

export const Home = createPage(() => {

    return <Fragment>
        <PageHeader
            title="Welcome to the Eye Surface Profiler"
            icon={EagletHomeIcon}
        >
            <p>Thank you for registering with us. To update your personal information or change your password, go to MY ACCOUNT.</p>
<p>Please go to MY COMPANY to set up your company and invite additional users.</p>

        </PageHeader>
    </Fragment>;
}, "Home")

import { CRUDField, CRUDFieldOptions } from "./Field";
import { CRUDFieldContext } from "./Reducer";
import { CRUDValues } from "./Values";

export type CRUDValuesProvider<T> = () => T;
export type CRUDIDProvider = (item: any) => string;

export interface CRUDTypeBase
{
    get formFields() : CRUDField[];
    findFormField(name: string): CRUDField | undefined;
    get displayFields() : CRUDField[];
}

export class CRUDType<T> implements CRUDTypeBase
{
    private _fields: CRUDField[] = [];
    private _id?: CRUDIDProvider;

    public constructor(base?: CRUDType<T>)
    {
        if (base)
        {
            this._fields = [...base._fields];
        }
    }

    public add(name: string, options: CRUDFieldOptions = {}): CRUDType<T>
    {
        this._fields.push(new CRUDField(name, options));
        return this;
    }

    public merge(name: string, options: CRUDFieldOptions = {}): CRUDType<T>
    {
        for(let i = 0; i < this._fields.length; ++i)
        {
            if (this._fields[i].fieldName === name)
            {
                this._fields[i] = this._fields[i].withMergedOptions(options);
                return this;
            }
        }

        // Not found, add
        // TODO: error?
        this.add(name, options);
        return this;
    }

    public with(name: string, options: CRUDFieldOptions = {}): CRUDType<T>
    {
        let c = new CRUDType<T>();
        c._id = this._id;

        // Deep copy of the fields
        this._fields.forEach((field) => c._fields.push(field.withMergedOptions({})));
        c.merge(name, options);
        return c;
    }

    public get formFields() : CRUDField[] {return this._fields.filter((e) => e.formField);}
    public findFormField(name: string): CRUDField | undefined
    {
        return this._fields.find((e) => e.fieldName == name);
    }

    public get displayFields() : CRUDField[] {return this._fields.filter((e) => e.showField);}
    public get tableFields() : CRUDField[] {return this._fields.filter((e) => e.showField && (e.options.table ?? true));}

    public newValues(context: CRUDFieldContext, values?: CRUDValuesProvider<T> | undefined): CRUDValues<T>
    {
        let v = new CRUDValues<T>(context);
        let init: any = values ? values() : {};
        this.formFields.forEach(field => {
            let value = Object.hasOwn(init, field.fieldName) ? init[field.fieldName] : field.initValue;
            v.set(field, value);
        });
        return v;
    }
    
    public emptyValues(context: CRUDFieldContext): CRUDValues<T>
    {
        let v = new CRUDValues<T>(context);
        this.formFields.forEach(field => {v.set(field, '')});
        return v;
    }

    public id(item: T): any
    {
        if (this._id)
            return this._id(item);
        return (item as any).id;
    }

    public makeId(id: CRUDIDProvider): CRUDType<T>
    {
        this._id = id;
        return this;
    }

    public field(name: string): CRUDField | undefined
    {
        for(let i = 0; i < this._fields.length; ++i)
        {
            if (this._fields[i].fieldName === name)
            {
                return this._fields[i];
            }
        }

        return undefined;
    }
}


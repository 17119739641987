import { Message } from "@mui/icons-material";
import { CRUDField } from "./Field";
import { CRUDFieldContext, FieldFeedback } from "./Reducer";

/*
 * General
 */

export enum ValidationState
{
    Unchecked,
    Success,
    Warning,
    Error
}

export abstract class Validation
{
    public abstract get state(): ValidationState;
    public abstract get message(): string | undefined;

    public get isError(): boolean
    {
        return this.state == ValidationState.Error;
    }
    public get isAcceptable(): boolean
    {
        return !this.isError;
    }
}

export class ValidationConstant extends Validation
{
    private _message: string | undefined;
    private _state: ValidationState;

    constructor(state: ValidationState, message: string | undefined)
    {
        super();
        this._state = state;
        this._message = message;
    }
    
    get state(): ValidationState 
    {
        return this._state;
    }

    get message(): string | undefined
    {
        return this._message;
    }
}

export const ValidationUnchecked: Validation = new ValidationConstant(ValidationState.Unchecked, undefined);
export const ValidationSuccess: Validation =  new ValidationConstant(ValidationState.Success, undefined)

export class ValidationError extends ValidationConstant
{
    constructor(message: string)
    {
        super(ValidationState.Error, message);
    }
}

export class ValidationCompoundError extends ValidationConstant
{
    constructor(errors: Validation[])
    {
        super(ValidationState.Error, "Multiple errors");
    }
}

/*
 * Field validation
 */
export interface FieldValidator
{
    validate(field: CRUDField, value: any, context: CRUDFieldContext, isInProgress: boolean, feedback?: FieldFeedback | undefined): Validation;
}

export class FieldValidatorNotEmpty implements FieldValidator
{
    public validate(field: CRUDField, value: any, context: CRUDFieldContext, isInProgress: boolean, feedback?: FieldFeedback | undefined): Validation
    {
        if (!!value && !feedback)
        {
            return ValidationSuccess;
        }
        else
        {
            return new ValidationError(`${field.displayName} cannot be empty`);
        }
    }
}

export class FieldValidatorMinLength implements FieldValidator
{
    private _length: number;

    public constructor(length: number)
    {
        this._length = length;
    }

    public validate(field: CRUDField, value: any, context: CRUDFieldContext, isInProgress: boolean, feedback?: FieldFeedback | undefined): Validation
    {
        if (!feedback && value && (value as string).length >= this._length)
        {
            return ValidationSuccess;
        }
        else
        {
            return new ValidationError(`${field.displayName} must be at least ${this._length} characters`);
        }
    }
}

import { Route } from "react-router-dom";
import { RequireAuth } from "../components/Auth";
import { FCPage } from "../components/PageBase";

export type RoutableEntryNode =
{
    path: string;
    element?: FCPage;
    auth?: boolean;

    children?: RoutableEntryNode[]

};

export type RoutableEntry = RoutableEntryNode & 
{
    group?: string | undefined;
    icon?: JSX.Element | null;
    menu?: undefined | 'primary' | 'secondary',
};

function makeElement(route: RoutableEntry, parent?: RoutableEntry)
{
    let tmp ={ element: route.element as FCPage};
    let auth = route.auth ?? parent?.auth;
    if (auth === false)
        return (<tmp.element/>);
    else
        return (<RequireAuth>{<tmp.element/>}</RequireAuth>);
}

export function mapRoutes(routes: readonly RoutableEntry[], parent?: RoutableEntry)
{
    return routes.map((route, index) =>
                    {
                        if (route.group)
                            return;

                        return <Route key={route.path} path={route.path}>

                            <Route index element={makeElement(route, parent)}/>
                           
                            { route.children && mapRoutes(route.children, route) }
                        </Route>;
                    })
}
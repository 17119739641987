import { Fragment } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CRUDEditor, CRUDService, CRUDTable, CRUDType } from "../../../shared/components/crud/index.d";
import { EPath } from "../../../shared/util/EPath";
import { API_PATH_ADMIN } from "../../Constants";
import { useDialogs } from "../../../shared/Dialogs";
import { v4 as uuidv4, NIL as uuidempty } from 'uuid';
import { FieldValidatorMinLength, FieldValidatorNotEmpty, Validation, ValidationError } from "../../../shared/components/crud/Validator";
import { CRUDField } from "../../../shared/components/crud/Field";
import { CRUDFieldContext } from "../../../shared/components/crud/Reducer";


export interface DirectConnectSupplier {
    readonly id: string;
    readonly displayName: string;
    readonly icon: string;
    readonly hasAccessControl: boolean;
    readonly accessGroupId: string;
}

export const DirectConnectSupplier_CT = new CRUDType<DirectConnectSupplier>()
    .add("id", {
        initValue: uuidv4
    })
    .add("displayName", {
        display: "Name",
        validator: new FieldValidatorMinLength(3)
    })
    .add("icon", {
        display: "Icon",
        type: "image"
    })
    .add("hasAccessControl", {
        display: "Access Control",
        type: "boolean",
        onValueChange: (field: CRUDField, value: any, context: CRUDFieldContext) => 
        {
            let otherField = context.type.findFormField('accessGroupId') as CRUDField;
            otherField.options.enabled = value ?? true;
        }
    })
    .add("accessGroupId", {
        display: "Access Control Group",
        type: "label",
        table: false,
        initValue: () => uuidempty,
        control: (field: CRUDField, value: any, context: CRUDFieldContext) => {
            if (!value || value == "00000000-0000-0000-0000-000000000000") // TODO: in lib
            {
                return (<Fragment/>);
            }

            // TODO: remove hard-coded link
            return (<Link to={"/client/group/" + value}>Manage</Link>);
        }
    })
;

enum DirectConnectLensType
{
    Scleral = 1,
    Hybrid = 2,
    RPG = 4,
    OrthoK = 8,
    Soft = 16
}
export interface DirectConnectLensSet {
    readonly id: string;
    readonly displayName: string;
    readonly icon?: string;
    readonly lensType: DirectConnectLensType[];
    readonly toolTip?: string;
    readonly requiresFirstLensFit: boolean;
    readonly firstLensFitId?: string;
    readonly overRefraction: boolean;
    readonly clearance: boolean;
    readonly diameters: number[];
    readonly continuationUrlTemplate: string;
}

export const DirectConnectLensSet_CT = new CRUDType<DirectConnectLensSet>()
    .add("id", {
        initValue: uuidv4
    })
    .add("displayName", {
        display: "Name",
        validator: new FieldValidatorMinLength(3)
    })
    .add("icon", {
        display: "Icon",
        type: "image"
    })
    .add("lensType", {
        display: "Lens Type",
        type: "enum[]",
        enumType: DirectConnectLensType
    })
    .add("toolTip", {
        display: "Tooltip",
        table: false
    })
    .add("requiresFirstLensFit", {
        display: "Requires First Lens Fit",
        type: "boolean",
        onValueChange: (field: CRUDField, value: any, context: CRUDFieldContext) => 
        {
            let enabled = value === true;
            console.log("requiresFirstLensFit", enabled);
            // First lens fit id is required if enabled
            let firstLensFitId = context.type.findFormField('firstLensFitId') as CRUDField;
            firstLensFitId.options.enabled = enabled;

            // And diameters isn't [#27]
            let diameters = context.type.findFormField('diameters') as CRUDField;
            diameters.options.enabled = !enabled;
        }
    })
    .add("overRefraction", {
        display: "Overrefraction",
        type: "boolean",
        initValue: () => true
    })
    .add("clearance", {
        display: "Clearance",
        type: "boolean",
        initValue: () => true
    })
    .add("firstLensFitId", {
        display: "First Lens Fit Supplier",
        table: false,
        validator: new FieldValidatorMinLength(3)
    })
    .add("diameters", {
        display: "Diameters",
        type: "double[]"
    })
    .add("continuationUrlTemplate", {
        display: "Url Template",
        table: false,
        type: "multiline",
        validator: new FieldValidatorNotEmpty()
    })

;

interface DirectConnectSupplierControlProperties
{
    versionKey: string;
    readOnly?: boolean;
}

interface DirectConnectSuppliersBaseProperties extends DirectConnectSupplierControlProperties
{
}

export const DirectConnectSuppliersBase = (props: DirectConnectSuppliersBaseProperties) => {
    
    const DirectConnectSupplier_CRUDService = new CRUDService(DirectConnectSupplier_CT, new EPath(API_PATH_ADMIN + "/share/services/directconnect/" + props.versionKey));

    return <Fragment>
        <CRUDTable<DirectConnectSupplier> 
            type={DirectConnectSupplier_CT}
            service={DirectConnectSupplier_CRUDService}
            title="DirectConnect Suppliers"
            createTitle="Create DirectConnect Supplier"
            readOnly={props.readOnly}
        />
    </Fragment>;
}


interface DirectConnectSupplierBaseProperties extends DirectConnectSupplierControlProperties
{
}

export const DirectConnectSupplierBase = (props: DirectConnectSupplierBaseProperties) => {
    let { id } = useParams();
    const DirectConnectSupplier_CRUDService = new CRUDService(DirectConnectSupplier_CT, new EPath(API_PATH_ADMIN + "/share/services/directconnect/" + props.versionKey));
    const DirectConnectLensSets_CRUDService = new CRUDService(DirectConnectLensSet_CT, new EPath(API_PATH_ADMIN + "/share/services/directconnect/"  + props.versionKey + "/" + id + "/sets"));

    let navigate = useNavigate();
    let dialogs = useDialogs();

    const handleClose = () =>
    {
        navigate(-1);
    };

    return <Fragment>
        <CRUDEditor<DirectConnectSupplier>
            type={DirectConnectSupplier_CT}
            service={DirectConnectSupplier_CRUDService}
            id={id as string}
            onClose={handleClose}
            readOnly={props.readOnly}
        />

        <CRUDTable<DirectConnectLensSet> 
            type={DirectConnectLensSet_CT}
            service={DirectConnectLensSets_CRUDService}
            title="DirectConnect Lens Sets"
            createTitle="Create DirectConnect Lens Set"
            readOnly={props.readOnly}
        />
    </Fragment>;
}

interface DirectConnectSupplierLensSetProperties extends DirectConnectSupplierControlProperties
{
}

export const DirectConnectLensSetBase = (props: DirectConnectSupplierLensSetProperties) => {
    let { id, setId } = useParams();
    const DirectConnectLensSets_CRUDService = new CRUDService(DirectConnectLensSet_CT, new EPath(API_PATH_ADMIN + "/share/services/directconnect/" + props.versionKey + "/" + id + "/sets"));

    let navigate = useNavigate();

    const handleClose = () =>
    {
        navigate(-1);
    };

    return <Fragment>
        <CRUDEditor<DirectConnectLensSet>
            type={DirectConnectLensSet_CT}
            service={DirectConnectLensSets_CRUDService}
            id={setId as string}
            onClose={handleClose}
            readOnly={props.readOnly}
        />
    </Fragment>;
}

import React, { Fragment } from 'react';
import './Layout.css';
import { NavSideBar } from '../shared/components/NavSideBar';
import { Header } from '../shared/components/Header';
import { RoutableEntry } from './util/Routing';
import { auth } from "./services/Auth.service";

type LayoutProps = {
    routes: readonly RoutableEntry[];
    children?: React.ReactNode;
};

export class Layout extends React.Component<LayoutProps> {
    renderContent() {
        if (auth.isLoggedIn)
        {
            return (<Fragment>
                <main id='content-full'>
                    <div id='content-full-content'>
                        {this.props.children}
                    </div>
                </main>
                <div id="content-full-footer">
                    Eye Surface Profiler
                </div>
                </Fragment>
            );
        }
        else
        {
            return (<Fragment>
                <main id='content-small'>
                    <div id='content-small-content'>
                        {this.props.children}
                    </div>
                </main>
                <div id="content-small-footer">
                    Eye Surface Profiler
                </div>
            </Fragment>
            );
        }
    }

    render() {
            return (<Fragment>
                <Header/>
                <div className="layout-container">
                    {
                        auth.isLoggedIn && (<NavSideBar routes={this.props.routes}/>)
                    }

                    <div id="main-container">
                        
                            {
                                this.renderContent()
                            }
                    </div>

                </div>
                </Fragment>)
    }
}

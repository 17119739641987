import {
    PhotoCamera as CameraIcon,
    Delete as DeleteIcon
}  from '@mui/icons-material';
import { Chip, ListItem, TextField } from '@mui/material';
import { useState } from "react";

// TODO: generalise this

type OnValueChangeEventHandler = (value: Number[] | null) => void;

interface DoubleArrayControlProperties
{
    label: string;
    name: string;
    id: string;
    value?: number[];

    onValueChange?: OnValueChangeEventHandler;
    readOnly?: boolean;
}


export const DoubleArrayControl = (props : DoubleArrayControlProperties) => 
{
    const [currentValues, setCurrentValues] = useState(props.value ?? [] as number[]);

    const makeNumber = (target: any) =>
    {
            // TODO: error handling
            let number = Number.parseFloat(target.value);
            if (Number.isNaN(number))
            {
                target.value = '';
                return;
            }

            // Add value
            let numbers = currentValues;
            // No duplicates
            if (numbers.indexOf(number) < 0)
            {
                setCurrentValues((old) =>
                {
                    // Need to set a new list, otherwise it doesn't re-render.
                    if (typeof(old) === "string")
                    {
                        old = [] as number[];
                    }
                    let newList = old.concat(number);
                    newList = newList.sort((a: number, b: number) => a - b );
                    props.onValueChange?.(newList);
                    return newList;
                });
            }

            // Clear text
            target.value = '';
    };

    const makeTagFromEnter = (e: React.KeyboardEvent<HTMLInputElement>): void =>
    {
        if (e.key == "Enter" || e.key == ' ')
        {
            // Enter
            makeNumber(e.target);
            e.preventDefault();
        }
        else if (e.key.length == 1)
        {
            if (e.key[0] > ' ' && e.key[0] <= '\x7F')
            {
                if ((e.key >= '0' && e.key <= '9') || e.key == '.')
                {
                    return;
                }
            }
            e.preventDefault();
        }
        else
        {
            // Control char, allowed
        }
    };
    const makeTagFromCurrent = (e: React.FocusEvent<HTMLInputElement>): void =>
    {
        makeNumber(e.target);
    };

    const handleDelete = (element: number) =>
    {
        props.onValueChange?.(currentValues.filter((item: Number) => item != element));
    };

    let inputProps = {};
    if (props.readOnly)
        inputProps = {readOnly: true};

    return (<div className='double_a_wrapper'>
        <div 
            className='double_a_elements'
            id={props.id + '-elements'}
        >
            {currentValues && currentValues.sort((a: number, b: number) => a - b).map((element: any) => {
                    return (<ListItem key={'entry-' + (element)}>
                    <Chip
                        label={element.toFixed(1)}
                        onDelete={props.readOnly ? undefined : (() => handleDelete(element))}
                    />
                    </ListItem>);
                })
            }
        </div>
        <TextField 
            name={props.name}
            id={props.id}
            variant='standard'
            label={props.label}
            onKeyDown={makeTagFromEnter}
            onBlur={makeTagFromCurrent}
            inputProps={inputProps}
        />
    </div>
    );
}
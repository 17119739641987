import { DispatchWithoutAction, useEffect, useState } from "react";

export function useStateAsync<S,Params>(initialState: S, asyncer: (p: Params) => Promise<S>, p: Params, deps?: any[]): [S, DispatchWithoutAction]
{
    let [val, setter] = useState({
        value: initialState,
        asyncer: asyncer
    });

    let [refresh, setRefresh] = useState(false);

    useEffect(() =>
    {
        // Start the actual fetch
        val.asyncer(p).then((result) =>
        {
            setter({
                value: result,
                asyncer: asyncer
            });
        });
    }, [...(deps ?? []), p, refresh]);

    return [val.value, () =>
    {
        setRefresh(!refresh);
    }];
}

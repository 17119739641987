import { Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab, Button, DialogActions } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { createPage } from "../../../shared/components/PageBase";
import { EPath } from "../../../shared/util/EPath";
import { ERequest, handleRequest, handleRequestTyped } from "../../../shared/util/ERequest";
import versionData from '../../../version.json';
import { addPathKeys } from "./DebugUtil";
import { CRUDService } from "../../../shared/components/crud/CRUDService";
import { CRUDTable } from "../../../shared/components/crud/CRUDTable";
import { API_PATH_ADMIN } from "../../Constants";
import { CRUDType } from "../../../shared/components/crud/CRUDType";

interface CacheEntry {}

export const CacheEntry_CT = new CRUDType<CacheEntry>()
    .makeId((item) => item.date)
    .add("date", {display: "Time"})
    .add("path", {display: "Path"})
    .add("sizeBytes", {display: "Size"})
    .add("expireAge", {display: "Expired Age", type: "boolean"})
    .add("expireSize", {display: "Expired Size", type: "boolean"})

;
const CacheEntry_Service = new CRUDService(CacheEntry_CT, new EPath(API_PATH_ADMIN + "/share/cache/entries"));

export const DebugCache = createPage(() => {

    let [settings, setSettings] = useState<{[key: string]: any}>({});

    const getServerSettings = (paths: string[]) =>
    {
        const get = (paths: string[], settingsCurrent: any) =>
        {
            if (paths.length == 0)
                return;

            let path = paths.shift()!;

            handleRequestTyped<any>(new ERequest(new EPath("/api/admin/share/cache").with(path)).exec(),
                (success) =>
                {
                    let loaded: {[key: string]: any} = {};
                    addPathKeys(loaded, success, path);
                    
                    let newSettings = Object.assign({...settingsCurrent}, loaded);
                    console.log("debug success", loaded, settingsCurrent, newSettings);
                    setSettings(newSettings);
                    get(paths, newSettings);
                },
                (err, json) =>
                {
                    console.log("debug error", err, json);
                    get(paths, settingsCurrent);
                }
            );
        };
        get(paths, settings);
    };

    useEffect(() =>
    {
        getServerSettings([""]);
    }, []);

    const purgeCache = () =>
    {
        handleRequest(new ERequest(new EPath("/api/admin/share/cache/purge")).exec(),
                (value) =>
                {
                    document.location.reload();
                },
                (err, json) =>
                {
                    console.log("cache error", err, json);
                }
            );
    };

    return <Fragment><h1>Settings</h1>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Property
                            </TableCell>
                            <TableCell>
                                Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.entries(settings).map(([key]) => <TableRow key={key}>
                                <TableCell>
                                    {key}
                                </TableCell>
                                <TableCell>
                                    {(settings as any)[key]}
                                </TableCell>
                            </TableRow>)
                        }
                    </TableBody>
                </Table>

                <hr/>
                
                <DialogActions>

                    <Button
                        onClick={purgeCache}
                    >
                        Purge cache
                    </Button>
                </DialogActions>

                <CRUDTable<CacheEntry> 
                    type={CacheEntry_CT}
                    service={CacheEntry_Service}
                    title="Cache entries"
                    sort={{field: CacheEntry_CT.field("date"), ascending: false}}
                    selectable={false}
                />
        </Fragment>;
}, "Cache")

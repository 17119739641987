import { CRUDType } from "../../../shared/components/crud/index.d";
import { FieldEmail } from "../../../shared/Fields";
import { AdminUser } from "../../model/Admins";
import { UserBase_CT } from "../client/ClientsCRUD";

export const AdminUser_CT = new CRUDType<AdminUser>(UserBase_CT);

export interface AdminUserInvite
{
    readonly email: string;
};

export const AdminUserInvite_CT = new CRUDType<AdminUserInvite>()
    .add("email", FieldEmail)
;

import { Box, Button, DialogActions, FormControl, Input, InputLabel, Paper, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FieldNewPassword, FieldNewPasswordConfirm } from '../Fields';
import { CRUDForm } from '../components/crud/CRUDForm';
import { CRUDService, CRUDType } from '../components/crud/index.d';
import { useCRUDReducer } from '../components/crud/Reducer';
import { createPage } from '../components/PageBase';
import { EPath } from '../util/EPath';
import { handleRequest } from '../util/ERequest';
import { useAuth } from '../components/Auth';

interface PasswordResetFormData
{
  token: string;
  newPassword1: string;
  newPassword2: string;
}

const PasswordResetData_CT = new CRUDType<PasswordResetFormData>()
    .add('token', {})
    .add('newPassword1', FieldNewPassword)
    .add('newPassword2', FieldNewPasswordConfirm)
;

// TODO: CT type
// TODO: path constant
//const Auth_CRUDService = new CRUDService(PasswordResetData_CT, new EPath("/api/user"));

const ResetPassword = (authService: any) => 
{
  let navigate = useNavigate();
  let token = new URLSearchParams(document.location.search).get('token');
  if (!token)
  {
      navigate('/');
  }

  const reducer = useCRUDReducer(PasswordResetData_CT, () =>
  {
      return  {
        token: token,
        newPassword1: '',
        newPassword2: ''
      }
  });
  let auth = useAuth();

  const submit = () =>
  {
    let v = reducer.values;
    console.log("Password reset", v);

    handleRequest(authService.callSelf('password/reset', {
        token: v.token,
        password: v.newPassword1
      }),
      (success) =>
      {
        console.log("Password reset ok", success);
        auth.refreshUser().then
            (
                (x) => 
                {
                  navigate("/", { replace: true });
                  document.location.reload();
                }
            );
      },
      (err, json) =>
      {
          console.log("Password reset error", err, json);
          reducer.onSubmitError(json);
      }
    );
  };

  console.log("fields",PasswordResetData_CT.formFields);

  return <Box>
    <Stack>
      <h1>Reset Password</h1>
      To reset your password, please enter your new password below.

      <CRUDForm<PasswordResetFormData> 
          type={PasswordResetData_CT}
          values={reducer}
          submit={(e) => e.preventDefault()}
      />

      <DialogActions>
        <Button
            color='primary'
            onClick={submit}
            disabled={!reducer.validate().isAcceptable}
        >
            Update password
        </Button>
      </DialogActions>
    </Stack>
  </Box>;
}

export const ResetPasswordUser = createPage(() => ResetPassword(new CRUDService(PasswordResetData_CT, new EPath("/api/user"))), 'Reset Password');
export const ResetPasswordAdmin = createPage(() => ResetPassword(new CRUDService(PasswordResetData_CT, new EPath("/api/admin/auth"))), 'Reset Password');


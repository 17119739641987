import { ReactElement, useState } from "react";
import { Navigation, NavItemProps } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { RoutableEntry } from "../util/Routing";
import { FCPage } from "./PageBase";

interface NavItems
{
  navItems: NavItemProps[];
  pathIndex: {[key: string]: NavItemProps};
}

export type SideBarProps = {
  routes: readonly RoutableEntry[];
  selectedPath?: string;
  navigate: (entry: RoutableEntry) => void;
};

export const SideBar : React.FC<SideBarProps> = ({routes, selectedPath, navigate}) => 
{
  let[collapsed, setCollapsed] = useState(false);

  const makeItems = (menu: string): NavItems => {
    let results: NavItemProps[] = [];

    type Grouping =
    {
      prefix: string;
      group: NavItemProps;
      children: NavItemProps[];
    };
    let lastGroup: Grouping[] = [];
    let pathIndex: {[key: string]: NavItemProps} = {};

    for(let i = 0; i < routes.length; ++i)
    {
      if (menu != routes[i].menu)
        continue;

      if (routes[i].group)
      {
        // TODO: index page for groups
        let group: NavItemProps = 
          {
            title: routes[i].group as string,
            itemId: routes[i].group as string,
            elemBefore: () => routes[i].icon as ReactElement
          };
          results.push(group);
          lastGroup.push({
            prefix: routes[i].path,
            group: group,
            children: []
          });
      }
      else
      {
        // New item
        let item: NavItemProps =
          {
            title: (routes[i].element as FCPage).title,
            itemId: routes[i].path,
            elemBefore: () => routes[i].icon as ReactElement
          };

        // See if it is in a group
        let found = false;
        for (let j = lastGroup.length - 1; j >= 0; --j)
        {
          if (item.itemId.startsWith(lastGroup[j].prefix))
          {
            lastGroup[j].children.push(item);
            found = true;
            break;
          }
        }

        if (!found)
          results.push(item);

        // An item can be a group too
        lastGroup.push({
            prefix: routes[i].path,
            group: item,
            children: []
          });

        // Path index
        pathIndex[routes[i].path] = item;
      }
    }

    lastGroup.forEach((item) => {
      item.group.subNav = item.children;
    });
    
    return {
      navItems: results,
      pathIndex: pathIndex
    };
  }

  let [items, setItems] = useState(makeItems('primary'));
  let [itemsBottom, setItemsBottom] = useState(makeItems('secondary'));

  const selectItem = ({ itemId }: any) =>
  {
    if (itemId.length > 0) 
    {
        let item = routes.find((i) => i.path == itemId);
        console.info("navigate", itemId, item);
        if (item)
            navigate(item as RoutableEntry);
    }
  };

  const activeItemId = (items: NavItems): string =>
  {
    let path = selectedPath ?? "";

    while(path.length > 1)
    {
      let navItem = items.pathIndex[path];
      if (navItem)
        return path;
        // TODO: use path function
      path = path.substring(0, path.lastIndexOf("/"));
    }
    return path;
  };

    return (
        <div
          className={`sidebar-container ${
            collapsed ? "ease-in -translate-x-full" : "ease-out translate-x-0"
        }`}
      >

        <Navigation
          activeItemId={activeItemId(items)}
          onSelect={selectItem}
          items={items.navItems}
        />

        <div className="nav-bottom-entry">
          <Navigation
            activeItemId={activeItemId(itemsBottom)}
            onSelect={selectItem}
            items={itemsBottom.navItems}
          />
        </div>
      </div>
    );
}

import { CloseOutlined } from "@mui/icons-material";
import { AnyARecord } from "dns";
import { useEffect, useReducer } from "react";
import { CRUDType } from ".";
import { CRUDTypeBase, CRUDValuesProvider } from "./CRUDType";
import { CRUDField } from "./Field";
import { Validation, ValidationCompoundError, ValidationError, ValidationSuccess } from "./Validator";
import { CRUDValues } from "./Values";

export interface CRUDFieldContext
{
    get type(): CRUDTypeBase;
    field(field: CRUDField): CRUDReducerField;

    isAutoFocusField(field:CRUDField): boolean;
    get autoFocus(): boolean;
    set autoFocus(value: boolean);
}

export enum FeedbackStatus
{
    None,
    Invalid,
    Duplicate,
    Explicit
}
export interface FieldFeedback
{
    get name(): string;
    get status(): FeedbackStatus;
    get message(): string;
}


export interface CRUDReducerField
{
    get(): any;
    set(value: any): void;

    validate(context: CRUDFieldContext, isInProgress: boolean): Validation;

    // TODO: is this just set now?
    onChange(value: any): void;

    wantsValidation(): boolean;
}

type CRUDValuesFactory<T> = () => CRUDValues<T>;

class CRUDReducerState<T>
{
    private _type: CRUDType<T>;
    private _factory: CRUDValuesFactory<T>;
    private _initial: CRUDValues<T>;
    current: CRUDValues<T>;

    constructor(type: CRUDType<T>, factory: CRUDValuesFactory<T>, current?: CRUDValues<T>)
    {
        this._type = type;
        this._factory = factory;
        this._initial = factory();
        this.current = current ?? this._initial;
    }

    get type(): CRUDType<T>
    {
        return this._type;
    }

    clone(): CRUDReducerState<T>
    {
        return new CRUDReducerState<T>(this._type, this._factory, this.current.clone());
    }    

    reset(): CRUDReducerState<T>
    {
        let s = new CRUDReducerState<T>(this._type, this._factory);
        s.current.clearWantsValidation();
        return s;
    }    

    hasChanges(): boolean
    {
        return !this.current.equals(this._initial);
    }

    onFeedback(feedback: any): CRUDReducerState<T>
    {
        let found = false;
        let clone = this.clone();

        console.log("handling feedback", feedback);
        feedback?.fields?.forEach?.((field: any) =>
            {
                console.log("feedback - field", field);
                for(let i in this._type.formFields)
                {
                    if (this._type.formFields[i].fieldName.toLowerCase() === field.name.toLowerCase())
                    {
                        console.log("feedback - field found", field);
                        clone.current.setFeedback(this._type.formFields[i], field);
                        found = true;
                        break;
                    }
                }
            });

        let error = feedback?.globalError ?? "An unknown error occurred"; // TODO: message;
        clone.current.globalError = found ? undefined : error;
        clone.current.globalMessage = undefined;
        return clone;
    }
}

export class CRUDReducer<T> implements CRUDFieldContext
{
    private _type: CRUDType<T>;
    private _state!: CRUDReducerState<T>;
    private _dispatch!: React.Dispatch<any>;
    // TODO: this is really a form property
    private _autoFocus: boolean = true;

    public constructor(type: CRUDType<T>)
    {
        this._type = type;
    }

    public get autoFocus(): boolean
    {
        return this._autoFocus;
    }

    public isAutoFocusField(field:CRUDField): boolean
    {
        if (!this._autoFocus)
            return false;

        // Auto-focus the first field
        return this._type.displayFields.indexOf(field) == 0
    }
    

    public set autoFocus(value: boolean)
    {
        this._autoFocus = value;
    }

    public get type(): CRUDTypeBase
    {
        return this._type;
    }
    
    public validate(): Validation
    {
        let errors: Validation[] = [];

        if (this.hasGlobalError)
        {
            errors.push(new ValidationError(this.globalErrorText)); 
        }

        this._type.formFields.forEach((field) =>
        {
            let fieldValid = this.field(field).validate(this, true);
            if (fieldValid.isError)
            {
                errors.push(fieldValid);
            }
        });

        console.log("validate", errors);
        let result =  (errors.length === 0) ? ValidationSuccess : new ValidationCompoundError(errors);
        return result;
    }

    public addFeedback(feedback: any, add: any): any
    {
        feedback = feedback ?? {};
        return Object.assign({...feedback}, add);
    }

    public fieldExplicitMessage(name: string, message: string): any
    {
        return {fields: [{name: name, status: FeedbackStatus.Explicit, message: message}]};
    }
    
    public onRequestError(response: Response, feedback?: any): void
    {
        console.log("onRequestError", response, feedback, response.bodyUsed);
        if (typeof(feedback) === "string")
            feedback = {globalError: feedback};
        this._dispatch({special: 'feedback', values: feedback});
        this.validate();
    }
    // TODO: remove, use onRequestError only?
    public onSubmitError(feedback?: any): void
    {
        console.log("feedback", feedback);
        this._dispatch({special: 'feedback', values: feedback});
        this.validate();
    }
    public setGlobalError(error?: string)
    {
        this._dispatch({special: 'feedback', values: {globalError: error}});
        this.validate();
    }

    public onSubmitSuccess(message?: string)
    {
        this._dispatch({special: 'reset', message: message});
    }

    public onSubmitSuccessNoReset(message?: string)
    {
        this._dispatch({special: 'success', message: message});
    }

    public get hasChanges(): boolean
    {
        return this._state.hasChanges();
    }

    public get hasGlobalMessage() {return !!this._state.current.globalMessage;}
    public get globalMessageText() {return this._state.current.globalMessage!; }

    public get hasGlobalError() {return !!this._state.current.globalError;}
    public get globalErrorText() {return this._state.current.globalError!; }

    public field(field: CRUDField): CRUDReducerField
    {
        const getValue = () => this._state.current.get(field);
        return {
            get: getValue,
            set: (value: any) => 
            {
                this._state.current.set(field, value);
            },
            onChange: (value: any) =>
            {
                this._dispatch({field: field, value: value});
            },
            validate: (context: CRUDFieldContext, isInProgress: boolean) =>
            {
                let feedback = this._state.current.getFeedback(field);
                return field.validate(getValue(), context, isInProgress, feedback);
            },
            wantsValidation: () =>
            {
                return this._state.current.wantsValidation(field);
            }

        };
    }

    public get values(): {[key: string]: any}
    {
        return this._state.current.values();
    }

    public get valuesSubmit(): {[key: string]: any}
    {
        return this._state.current.valuesSubmit(this._type);
    }

    init(state: CRUDReducerState<T>, dispatch: React.Dispatch<CRUDField>)
    {
        this._state = state;
        this._dispatch = dispatch;
    }

    public setValues(values: any)
    {
        this._dispatch({special: 'init', values: values});
    }

    static reduce<T>(state: CRUDReducerState<T>, action: any): CRUDReducerState<T>
    {
        if (action.special === 'reset')
        {
            // Reset
            let s = state.reset();
            s.current.globalMessage = action.message;
            s.current.notify();
            return s;
        }
        else if (action.special === 'success')
        {
            // Reset
            let s = state.clone();
            s.current.globalMessage = action.message;
            return s;
        }
        else if (action.special === 'init')
        {
            let s = new CRUDReducerState<T>(state.type, () => action.values);
            s.current.globalMessage = action.message;
            s.current.notify();
            return s;
        }
        else if (action.special === 'feedback')
        {
            return state.onFeedback(action.values);
        }
        else if (action.field)
        {
            // Update field
            let s = state.clone();
            s.current.set(action.field as CRUDField, action.value);
            s.current.notify(action.field as CRUDField);
            return s;
        }

        return state;
    }

    reset(): void
    {
        this._dispatch({special: 'reset'});
    }
}

export function useCRUDReducer<T extends unknown>(type: CRUDType<T>, values?: CRUDValuesProvider<T> | undefined): CRUDReducer<T>
{
    let reducer = new CRUDReducer<T>(type);
    let factory: CRUDValuesFactory<T>;
    const [state, dispatch] = useReducer(CRUDReducer.reduce, new CRUDReducerState<T>(type, () => type.newValues(reducer, values)));
    reducer.init(state, dispatch);
    return reducer;
}

export function useCRUDReducerAsync<T extends unknown>(type: CRUDType<T>, asyncer?: () => Promise<T> ): CRUDReducer<T>
{
    let reducer = new CRUDReducer<T>(type);
    const [state, dispatch] = useReducer(CRUDReducer.reduce, new CRUDReducerState<T>(type, () => type.emptyValues(reducer)));
    reducer.init(state, dispatch);

    useEffect(() =>
    {
        if (asyncer)
        {
            asyncer().then((item: any) =>
            {
                // Convert to CRUDValues here, as the reduce function does not have access to the type.
                let values = new CRUDValues<T>(reducer);
                values.init(type, item);
                dispatch({special: 'init', values: values });
            });
        }
        else
        {
            let values = type.newValues(reducer);
            dispatch({special: 'init', values: values });
    }
    }, []);
    return reducer;
}

import { Fragment } from "react";
import { createPage } from "../../../shared/components/PageBase";
import { useSearchParams } from "react-router-dom";
import { Link, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

type AccessEntry =
{
    label: string;
    suffix: string;
};

const ACCESS_ENTRIES: readonly AccessEntry[] =
[
    {label: "MSR", suffix: ""},

    {label: "Image - Before", suffix: "/image/before.png"},
    {label: "Image - After", suffix: "/image/after.png"},
    {label: "Image - Source", suffix: "/image/source.png"},
    {label: "Image - Focus", suffix: "/image/focus.png"},
    {label: "Image - Grid", suffix: "/image/grid.png"},

    {label: "Bisphere - CSV", suffix: "/map/bisphere.csv"},
    {label: "Bisphere - JSON", suffix: "/map/bisphere.json"},
    {label: "Bisphere - Image", suffix: "/map/bisphere.png"},
    {label: "Bisphere - Image with Eye and Rulers", suffix: "/map/bisphere.png?rulers=true&eye=true"},

    {label: "Height - CSV", suffix: "/map/height.csv"},
    {label: "Height - JSON", suffix: "/map/height.json"},
    {label: "Height - Image", suffix: "/map/height.png"},
    {label: "Height - Image with Eye and Rulers", suffix: "/map/height.png?rulers=true&eye=true"},

    {label: "Tangent Angles - CSV", suffix: "/map/tangentangles.csv"},
    {label: "Tangent Angles - JSON", suffix: "/map/tangentangles.json"},
    {label: "Tangent Angles - Image", suffix: "/map/tangentangles.png"},
    {label: "Tangent Angles - Image with Eye and Rulers", suffix: "/map/tangentangles.png?rulers=true&eye=true"},

    {label: "Corneal Elevation - CSV", suffix: "/map/cornealelevation.csv"},
    {label: "Corneal Elevation - JSON", suffix: "/map/cornealelevation.json"},
    {label: "Corneal Elevation - Image", suffix: "/map/cornealelevation.png"},
    {label: "Corneal Elevation - Image with Eye and Rulers", suffix: "/map/cornealelevation.png?rulers=true&eye=true"},

    {label: "Axial Curvature - CSV", suffix: "/map/axialcurvature.csv"},
    {label: "Axial Curvature - JSON", suffix: "/map/axialcurvature.json"},
    {label: "Axial Curvature - Image", suffix: "/map/axialcurvature.png"},
    {label: "Axial Curvature - Image with Eye and Rulers", suffix: "/map/axialcurvature.png?rulers=true&eye=true"},
	
    {label: "Tangential Curvature - CSV", suffix: "/map/tangentialcurvature.csv"},
    {label: "Tangential Curvature - JSON", suffix: "/map/tangentialcurvature.json"},
    {label: "Tangential Curvature - Image", suffix: "/map/tangentialcurvature.png"},
    {label: "Tangential Curvature - Image with Eye and Rulers", suffix: "/map/tangentialcurvature.png?rulers=true&eye=true"},
	
    {label: "Refractice Power - CSV", suffix: "/map/refractivepower.csv"},
    {label: "Refractice Power - JSON", suffix: "/map/refractivepower.json"},
    {label: "Refractice Power - Image", suffix: "/map/refractivepower.png"},
    {label: "Refractice Power - Image with Eye and Rulers", suffix: "/map/refractivepower.png?rulers=true&eye=true"},

    {label: "Scleral Profile - Image ", suffix: "/map/scleralprofile.png"},

    {label: "Notes ", suffix: "/map/notes.txt"},

    {label: "Export - FirstLensFit", suffix: "/export/flf.json?rhoStep=1&angleStep=5"}
];

const PARAM_ENTRIES: readonly AccessEntry[] =
[
    {label: "All", suffix: "/param.json"},
    {label: "SpheroCylinder", suffix: "/param/SpheroCylinder.json"},
    {label: "BiSphere", suffix: "/param/BiSphere.json"},
    {label: "SphereFit", suffix: "/param/SphereFit.json"},
    {label: "K", suffix: "/param/K.json"},
    {label: "SimK", suffix: "/param/SimK.json"},
    {label: "Iris", suffix: "/param/Iris.json"},
    {label: "Pupil", suffix: "/param/Pupil.json"},
    {label: "Pingueculas", suffix: "/param/Pingueculas.json"},
    {label: "QA", suffix: "/param/QA.json"}

];

export const DirectConnectTestSupplier = createPage(() => {
    const [searchParams] = useSearchParams();
          
    return (<Fragment>
        <h1>Meta</h1>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Name
                    </TableCell>
                    <TableCell>
                        Value
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    
                    Array.from(searchParams.keys()).map((key) => <TableRow key={key}>
                                <TableCell>
                                    {key}
                                </TableCell>
                                <TableCell>
                                    {searchParams.get(key)}
                                </TableCell>
                            </TableRow>)
                }
            </TableBody>
        </Table>
        <h1>Maps and Exports</h1>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Description
                    </TableCell>
                    <TableCell>
                        Link Suffix
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    ACCESS_ENTRIES.map((entry) => <TableRow  key={entry.label}>
                                <TableCell>
                                    {entry.label}
                                </TableCell>
                                <TableCell>
                                    <Link  target="_blank" href={searchParams.get("url")! + entry.suffix}>{entry.suffix === "" ? "No suffix" : entry.suffix}</Link>
                                </TableCell>
                            </TableRow>)
                }
            </TableBody>
        </Table>
        <h1>Parameters</h1>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        Description
                    </TableCell>
                    <TableCell>
                        Link Suffix
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    PARAM_ENTRIES.map((entry) => <TableRow  key={entry.label}>
                                <TableCell>
                                    {entry.label}
                                </TableCell>
                                <TableCell>
                                    <Link  target="_blank" href={searchParams.get("url")! + entry.suffix}>{entry.suffix === "" ? "No suffix" : entry.suffix}</Link>
                                </TableCell>
                            </TableRow>)
                }
            </TableBody>
        </Table>
    </Fragment>);
}, "Test Supplier");
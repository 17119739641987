import { ToString } from "./Utils";

export class EPath 
implements ToString
{
    private _path: string; 
    public get path(): string { return this._path; }

    public constructor(path: string)
    {
        this._path = path;
    }

    /**
     * Returns a new path with the child added to this. 
     * 
     * @param child
     * The path to add.
     */
    public with(child: string): EPath
    {
        // Determine which have separators
        let count = 0;
        if (this._path.endsWith('/'))
            ++count;
        if (child.startsWith('/'))
            ++count;

        // And combine
        if (count === 0)
        {
            return new EPath(this._path + '/' + child);
        }
        else if (count === 1)
        {
            return new EPath(this._path + child);
        }
        else
        {
            return new EPath(this._path + child.substring(1));
        }
    }

    /**
     * Binds the first parameter in the path.
     * @param parameter The value to bind
     */
    public bind(parameter: any): EPath
    {
        let parts = this._path.split(/(\{[a-zA-Z]+\})/);
        let s = "";
        let done = false;

        parts.forEach((part) =>
        {
            if (!done && part.startsWith('{') && part.endsWith('}'))
            {
                // Parameter
                s += parameter;
                done = true;
            }
            else
            {
                s += part;
            }
        });
        
        console.info("path parse", this._path, s);
        return new EPath(s);
    }

    public toString(): string {return this.path; }
}

/**
 * Combines a Path with query parameters
 */
export class EQuery
{
    private _path: EPath;
    private _query: {[key: string]: any};

    public constructor(path: EPath)
    {
        let i = path.path.indexOf('?');
        if (i >= 0)
        {
            this._path = new EPath(path.path.substring(0, i));

            let qs = path.path.substring(i + 1);
            
            this._query = qs.split('&').reduce((data: any, item: any) => {

                const [rawKey, rawValue] = item.split('=');
                const key = decodeURIComponent(rawKey);
                const value = decodeURIComponent(rawValue);
          
                // Sometimes a query string can have multiple values 
                // for the same key, so to factor that case in, you
                // could collect an array of values for the same key
                if(data[key] !== undefined) {
                  
                  // If the value for this key was not previously an
                  // array, update it
                  if(!Array.isArray(data[key])) {
                    data[key] = [ data[key] ]
                  }       
                  
                  data[key].push(value)
                }
                else {
                  
                  data[key] = value
                }
          
                return data
          
              }, {});
        }
        else
        {
            this._path = path;
            this._query = {};
        }
    }

    public withQuery(name: string, value: any): EQuery
    {
        let c = this.clone();
        c._query[name] = value;
        return c;
    }

    public clone(): EQuery
    {
        let c = new EQuery(this._path);
        c._query = Object.assign({}, this._query);
        return c;
    }

    public toRequest(): string
    {
        let url = this._path.path;

        let s = "";
        if (this._query)
        {
            for (let key in this._query)
            {
                if (s.length > 0)
                    s += "&";
                else
                    s += "?";
                // TODO: encoding
                s += key + "=" + this._query[key];
            }
            if (s.length > 0)
                url += s;
        }
        
        return url;
    }

    public toString(): string {return this.toRequest(); }

}
import { Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab, Button } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { createPage } from "../../../shared/components/PageBase";
import { EPath } from "../../../shared/util/EPath";
import { ERequest, handleRequestTyped } from "../../../shared/util/ERequest";
import { addPathKeys } from "./DebugUtil";


export const DebugServices = createPage(() => {

    

    let [services, setServices] = useState<{[key: string]: any}>({});
    const getServices = () =>
    {
        handleRequestTyped<any>(new ERequest(new EPath("/api/services")).exec(),
                (success) =>
                {
                    let loaded: {[key: string]: any} = {};
                    addPathKeys(loaded, success, "/");
                    
                    let newServices = Object.assign({...services}, loaded);
                    console.log("services success", loaded, services, newServices);
                    setServices(newServices);
                },
                (err, json) =>
                {
                    console.log("services error", err, json);
                }
            );
    };

    useEffect(() =>
    {
        getServices();
    }, []);


    return <Fragment><h1>Services</h1>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Property
                            </TableCell>
                            <TableCell>
                                Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.entries(services).map(([key]) => <TableRow key={key}>
                                <TableCell>
                                    {key}
                                </TableCell>
                                <TableCell>
                                    {(services as any)[key]}
                                </TableCell>
                            </TableRow>)
                        }
                    </TableBody>
                </Table>
    </Fragment>;
}, "Services")

import { Button } from "@mui/material";
import { Fragment } from "react";
import { CRUDReducer } from "./crud/Reducer";


interface FormButtonsProperties
{
    submit?: () => void;
    submitLabel?: string;
    values?: CRUDReducer<any>;
    buttonClassName?: string;
}
export const FormButtons = (props : FormButtonsProperties) => 
{
    return (<Fragment>
            <Button
                color='primary'
                type='submit'
                onClick={props.submit}
                disabled={props.values && !props.values.validate().isAcceptable}
                className={props.buttonClassName}
            >
                {props.submitLabel ?? "Create"}
            </Button>
        </Fragment>
    );
}
import { ReactElement, useState } from "react";
import { Navigation, NavItemProps } from "react-minimal-side-navigation";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { useLocation, useNavigate } from "react-router-dom";
import { RoutableEntry } from "../util/Routing";
import { SideBar } from "./SideBar";

interface NavItems
{
  navItems: NavItemProps[];
  pathIndex: {[key: string]: NavItemProps};
}

export type NavSideBarProps = {
  routes: readonly RoutableEntry[];
};

export const NavSideBar : React.FC<NavSideBarProps> = ({routes}) => 
{
  let navigate = useNavigate();
  let location = useLocation();
  
  const doNavigate = (re: RoutableEntry) =>
  {
    navigate(re.path);
  };

  return (<div className="nav-container">
      <SideBar 
        routes={routes}
        selectedPath={location.pathname}
        navigate={doNavigate}
      /></div>);
}

import { CRUDType } from "../components/crud/CRUDType";
import { FieldValidatorMinLength, FieldValidatorNotEmpty } from "../components/crud/Validator";
import { CRUDFieldOptions } from "../components/crud/Field";

export interface EntityBase {
    readonly displayName: string;
}


export const FieldLogin: CRUDFieldOptions =
{
    display: "Login",
    placeholder: "Login",
    validator: new FieldValidatorMinLength(1)
};

export const EntityBase_CT = new CRUDType<EntityBase>()
    .add("displayName", {
        display: "Name",
        validator: new FieldValidatorNotEmpty()
    })
;

import { Link, To, useNavigate } from "react-router-dom";

interface LinkButtonProps {
    children?: React.ReactNode;
    to: To;
  }
  
export function LinkButton(props: LinkButtonProps) {
    return (
      <div
        className="link-button"
      >
        <Link to={props.to}>{props.children}</Link>
      </div>
    );
  }
  
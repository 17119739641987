import { DialogActions,Stack } from '@mui/material';
import { createPage, PageContent } from "../../../shared/components/PageBase";
import { useCRUDReducer } from "../../../shared/components/crud/Reducer";
import { CRUDForm, CRUDService, CRUDType } from "../../../shared/components/crud/index.d";
import { FormButtons } from "../../../shared/components/FormButtons";
import { EPath } from "../../../shared/util/EPath";
import { handleRequest } from "../../../shared/util/ERequest";
import { FieldNewPassword, FieldNewPasswordConfirm, FieldToken } from '../../../shared/Fields';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../shared/components/Auth';
import { FieldValidatorNotEmpty } from '../../../shared/components/crud/Validator';

interface CreateUserAccountFormData
{
    displayName: string;
    newPassword1: string;
    newPassword2: string;
}

const CreateUserAccountFormData_CT = new CRUDType<CreateUserAccountFormData>()
    .add("displayName", {
        placeholder: "Full name",
        display: "Name",
        validator: new FieldValidatorNotEmpty()
    })
    .add("newPassword1", FieldNewPassword)
    .add("newPassword2", FieldNewPasswordConfirm)
;

// TODO: CT type
// TODO: path constant
const UserSignUp_CRUDService = new CRUDService(CreateUserAccountFormData_CT, new EPath("/api/user"));

export const UserSignUp = createPage(() => {

    let navigate = useNavigate();
    let token = new URLSearchParams(document.location.search).get('token');
    if (!token)
    {
        //navigate('/');
    }
    let auth = useAuth();

    const reducer = useCRUDReducer(CreateUserAccountFormData_CT);
  
    const submit = () =>
    {
      let v = reducer.values;
      console.log("submit", v);

      handleRequest(UserSignUp_CRUDService.postToFunction("signup", {
        displayName: v.displayName,
        token: token,
        password: v.newPassword1
      }),
        (success) =>
        {
            console.log("confirm success", success);
            auth.refreshUser().then
            (
                (x) => 
                {
                    navigate("/", { replace: true });
                    document.location.reload();
                }
            );
        },
        (err, json) =>
        {
            console.log("confirm error", err, json);
            reducer.onSubmitError(json);
        }
      );
    };

    return <PageContent>
            Thank you for confirming your email address.
            To complete your account, please complete the form below.

            <Stack>
                <CRUDForm<CreateUserAccountFormData> 
                    type={CreateUserAccountFormData_CT}
                    values={reducer}
                    submit={submit}
                />

                <DialogActions>
                    <FormButtons
                        values={reducer}
                        submit={submit}
                        submitLabel="Create account"
                    />
                </DialogActions>
            </Stack>

    </PageContent>
    ;
}, "Sign up")

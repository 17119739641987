import { ContentPasteSearchOutlined } from "@mui/icons-material";
import { Button, FormControl, Input, InputLabel} from "@mui/material";
import React, { useReducer, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../components/Auth";
import { createPage } from "../components/PageBase";

interface IState {
  username: string;
  password: string;
}
const initialState: IState = 
{
  username: "",
  password: ""
};

interface IAction {
  type: string;
  value: any;
}

function reducer(state: IState, action: IAction): IState {
  if (action.type === "reset") {
      return initialState;
  }

  const result: IState = { ...state };
  result[action.type as keyof IState] = action.value;
  return result;
}

// TODO: separate form from login logic and redirecting
export const Login = createPage(() => 
{
  
  const [state, dispatch] = useReducer(reducer, initialState);
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name, value });
  };
  const [failed, setFailed] = useState(false);

  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  
  let from = (location.state as any)?.from?.pathname || "/";

  const submitForm = (event: React.FormEvent<HTMLFormElement>) => {
    console.info("submitForm", event);
    event.preventDefault();
  
      let formData = new FormData(event.currentTarget);
  
      console.info("Login", state);
      setFailed(false);
      auth.signin(state.username, state.password, (user?: any) => {
        console.info("Login result", user, from);
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        if (user)
        {
          navigate(from, { replace: true });
          document.location.reload();
        }
        else
        {
          setFailed(true);
        }
      });
    }

  return (
    <div className="form-container">
    <h2>Sign in</h2>
    { failed && (
      <div className="form-error">
          Login failed
      </div>
    )}
    <form className="form" onSubmit={submitForm}>
      <FormControl
        fullWidth
      >
        <InputLabel htmlFor="username">Username or email</InputLabel>
        <Input 
          type="text"
          name="username"
          id="username"
          value={state.username}
          onChange={onChange}
          placeholder="user@example.com"
        />
      </FormControl>

      <FormControl
        fullWidth
      >
        <InputLabel htmlFor="password">Password</InputLabel>
        <Input
          type="password"
          name="password"
          id="password"
          value={state.password}
          onChange={onChange}
          placeholder="********"
        />
      </FormControl>
      <Button variant="contained" color="primary" type="submit">
        Login
      </Button>
    </form>
  </div>
  );
}, "Login");

import { EPath } from "../../shared/util/EPath";
import { ERequest } from "../../shared/util/ERequest";
import { useTest } from "../TestFramework";

export const TestRequestGet = () => 
{
    return useTest("Request GET", (test) =>
    {
        /*
        new ERequest(new EPath("/api/test"))
            .method("get")
            .exec()
            .then(test.assertResponseStatus(200))
            .asString()
            .then(test.assert("'abc'"))
        ;*/
    });
};

export const TestRequestGetNotFound = () => 
{
    return useTest("Request GET Not Found", (test) =>
    {
        new ERequest(new EPath("/api/test_doesnt_exist"))
            .method("get")
            .exec()
            .then(test.assertResponseStatus(404))
        ;
    });
};

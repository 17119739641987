import { Routes } from 'react-router-dom';
import { ADMIN_APP_ROUTES } from './AdminAppRoutes';
import { AuthProvider } from '../shared/components/Auth';
import { Layout } from '../shared/Layout';
import { ConfirmProvider } from 'material-ui-confirm';
import { mapRoutes } from '../shared/util/Routing';
import { DialogsProvider } from '../shared/Dialogs';

export function AdminApp() {
    return (
        <ConfirmProvider><DialogsProvider><AuthProvider>
            <Layout routes={ADMIN_APP_ROUTES}>
                <Routes>
                {
                    mapRoutes(ADMIN_APP_ROUTES)
                }
                </Routes>
            </Layout>
        </AuthProvider></DialogsProvider></ConfirmProvider>
    );
}


import { alpha, Toolbar, Typography } from "@mui/material";

interface SelectionModel
{
    isEmpty(): boolean;
    count(): number;
    toggle(item: any, id: string): void;
    isSelected(id: string): boolean;
}

export interface TableAction
{
    needsSelection?: boolean;
    element: JSX.Element;
}

interface TableHeaderProperties
{
    title: string;
    selection: SelectionModel;
    actions?: TableAction[];
}
export const TableHeader = ({title, selection, actions} : TableHeaderProperties) => 
{
    return (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(!selection.isEmpty() && {
              bgcolor: (theme: any) =>
                alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
          }}
        >
          {!selection.isEmpty() ? (
            <Typography
              sx={{ flex: '1 1 100%' }}
              color="inherit"
              variant="subtitle1"
              component="div"
            >
              {selection.count()} selected
            </Typography>
          ) : (
            <Typography
              sx={{ flex: '1 1 100%' }}
              variant="h6"
              id="tableTitle"
              component="div"
            >
              {title}
            </Typography>
          )}

          {
            actions?.map
            (
                (action) => {
                    if (action.needsSelection === undefined || action.needsSelection ===  !selection.isEmpty())
                    {
                        return action.element;
                    }
                }
            )
          }

        </Toolbar>
      );
}

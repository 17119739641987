import { Fragment, useState } from "react";
import { CRUDService, CRUDTable, CRUDType } from "../../../shared/components/crud/index.d";
import { createPage } from "../../../shared/components/PageBase";
import { EPath } from "../../../shared/util/EPath";
import { API_PATH_ADMIN } from "../../Constants";
import { Tab, Tabs } from "@mui/material";
import { Switchable } from "../../../shared/components/Switchable";

interface DirectConnectUploadEntry
{
}

export const DirectConnectUploadEntry_CT = new CRUDType<DirectConnectUploadEntry>()
    .add("user.displayName", {display: "User"})
    .add("user.email", {display: "User"})
    .add("timestamp", {display: "Time"})
    .add("computer", {display: "Computer"})
    .add("address", {display: "Address"})
    .add("service_SupplierName", {display: "Supplier"})
    .add("service_DisplayName", {display: "Service"})
    .add("files", {display: "Files"})
;

interface DirectConnectDownloadEntry
{

}

export const DirectConnectDownloadEntry_CT = new CRUDType<DirectConnectDownloadEntry>()
    .add("user.displayName", {display: "User"})
    .add("user.email", {display: "User"})
    .add("timestamp", {display: "Time"})
    .add("computer", {display: "Computer"})
    .add("address", {display: "Address"})
    .add("file", {display: "File"})
    .add("accessPath", {display: "Path"})
    .add("accessParameters", {display: "Parameters"})
;

const Uploads_Service = new CRUDService(DirectConnectUploadEntry_CT, new EPath(API_PATH_ADMIN + "/share/services/directconnect/analytics/uploads"));
const Downloads_Service = new CRUDService(DirectConnectDownloadEntry_CT, new EPath(API_PATH_ADMIN + "/share/services/directconnect/analytics/downloads"));

export const DirectConnectAnalytics = createPage(() => {

    const [currentTab, setCurrentTab] = useState("uploads");

    return <div>
            <Tabs value={currentTab} onChange={(e, n) => setCurrentTab(n)}>
                <Tab label="Uploads" value="uploads" />
                <Tab label="Downloads" value="downloads" />
            </Tabs>

        <Switchable index={"uploads"} value={currentTab}>
            <CRUDTable<DirectConnectUploadEntry> 
                type={DirectConnectUploadEntry_CT}
                service={Uploads_Service}
                title="Recent uploads"
                sort={{field: DirectConnectUploadEntry_CT.field("timestamp"), ascending: false}}
            />
        </Switchable>

        <Switchable index={"downloads"} value={currentTab}>
            <CRUDTable<DirectConnectUploadEntry> 
                type={DirectConnectDownloadEntry_CT}
                service={Downloads_Service}
                title="Recent downloads"
                sort={{field: DirectConnectDownloadEntry_CT.field("timestamp"), ascending: false}}
            />
        </Switchable>
    </div>;
}, "Analytics")

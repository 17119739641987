import { Fragment, useEffect, useState } from "react";
import { createPage, PageHeader } from "../components/PageBase";
import { ChangePassword } from "../forms/ChangePassword";
import EagletAccountIcon from '../../shared/images/Icon_Account.png';
import { Link } from "react-router-dom";
import { FieldValidatorNotEmpty } from "../components/crud/Validator";
import { useCRUDReducer } from "../components/crud/Reducer";
import { Box, Button, DialogActions, Stack } from "@mui/material";
import { CRUDForm, CRUDService, CRUDType } from "../components/crud/index.d";
import { handleRequest } from "../util/ERequest";
import { EPath } from "../util/EPath";


interface AccountDetailsData
{
    displayName: string;
}

const AccountDetailsData_CT = new CRUDType<AccountDetailsData>()
    .add("displayName", {
        placeholder: "Full name",
        display: "Name",
        validator: new FieldValidatorNotEmpty()
    })
;
const AccountDetails_CRUDService = new CRUDService(AccountDetailsData_CT, new EPath("/api/user"));

const ChangeAccountDetails = () => 
{
  const reducer = useCRUDReducer(AccountDetailsData_CT);
  useEffect(() =>
  {
      handleRequest(AccountDetails_CRUDService.getSelf(), (p) => {
          p.json().then((p:any) => 
          {
              console.log("account", p);
              reducer.setValues(AccountDetailsData_CT.newValues(reducer, () => p));
          });
      });
  }, []);


  const submit = () =>
  {
    let v = reducer.values;
    console.log("Account details update", v);
    
    handleRequest(AccountDetails_CRUDService.post(reducer.values),
      (success) =>
      {
          console.log("confirm success", success);
          reducer.onSubmitSuccessNoReset("Your account has been updated");
      },
      (err, feedback) =>
      {
          console.log("confirm error", err, feedback);
          reducer.onSubmitError(feedback);
      }
    );
  };

  return <Box className='form-wrapper'>
    <Stack>
      <CRUDForm<AccountDetailsData> 
          type={AccountDetailsData_CT}
          values={reducer}
          submit={(e) => e.preventDefault()}
      />

      <DialogActions>
        <Button
            color='primary'
            onClick={submit}
            disabled={!reducer.hasChanges || !reducer.validate().isAcceptable}
        >
            Update details
        </Button>
      </DialogActions>
    </Stack>
  </Box>;
};


export const MyAccountPage = createPage(() => {

    return <Fragment>

        <PageHeader
            title="My Account"
            icon={EagletAccountIcon}
        >
            <p>Change your account details or password here.</p>

        </PageHeader>

        <h2>Change your details</h2>
        <ChangeAccountDetails/>

        <hr/>

        <h2>Change your password</h2>
        <p>Forgot your password? Please <Link to="/login/reset">reset it here.</Link></p>

        <ChangePassword
            autoFocus={false}
        />
    </Fragment>;
}, "My Account")

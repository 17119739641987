import { createPage } from "../../../shared/components/PageBase";
import { CRUDService } from "../../../shared/components/crud/CRUDService";
import { CRUDTable } from "../../../shared/components/crud/CRUDTable";
import { CRUDType } from "../../../shared/components/crud/CRUDType";
import { EPath } from "../../../shared/util/EPath";
import { API_PATH_ADMIN } from "../../Constants";

interface LogEntry
{
}


export enum LogLevel
{
    Trace,
    Debug,
    Information,
    Warning,
    Error,
    Critical,
    None
}

export const LogEntry_CT = new CRUDType<LogEntry>()
    .add("timestamp", {display: "Time"})
    .add("level", {display: "Level", enumType: LogLevel})
    .add("text", {display: "Text", })
    .add("stackTrace", {display: "Stack Trace"})

;
const Log_Service = new CRUDService(LogEntry_CT, new EPath(API_PATH_ADMIN + "/debug/log"));
const StartupLog_Service = new CRUDService(LogEntry_CT, new EPath(API_PATH_ADMIN + "/debug/startupLog"));

export const DebugLogs = createPage(() => {
    return <CRUDTable<LogEntry> 
        type={LogEntry_CT}
        service={Log_Service}
        title="Recent events"
        sort={{field: LogEntry_CT.field("timestamp"), ascending: false}}
        selectable={false}
        classNamePrefix="debug-logs-"
    />
}, "Logs");

export const DebugStartupLogs = createPage(() => {
    return <CRUDTable<LogEntry> 
        type={LogEntry_CT}
        service={StartupLog_Service}
        title="Startup events"
        sort={{field: LogEntry_CT.field("timestamp"), ascending: false}}
        selectable={false}
        classNamePrefix="debug-logs-"
    />
}, "Startup Logs");

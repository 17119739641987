export const addPathKeys = (loaded: {[key: string]: any}, obj: any, path: string) =>
{
    console.log("addLoaded", path);
    if (!obj)
        return;
    Object.entries(obj).forEach(([key, val]) => 
    {
        if (typeof(val) === "object")
        {
            addPathKeys(loaded, val, path + "/" + key);
        }
        else
        {
            loaded[path + "/" + key] = val;
        }
    });
};
import { DialogActions,Stack } from '@mui/material';
import { createPage, PageContent } from "../../shared/components/PageBase";
import { CRUDForm, CRUDType } from "../../shared/components/crud/index.d";
import { useState } from "react";
import { FieldLoginOrEmail, FieldLoginPassword } from '../../shared/Fields';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useCRUDReducer } from '../../shared/components/crud/Reducer';
import { FormButtons } from '../../shared/components/FormButtons';
import { useAuth } from '../../shared/components/Auth';

interface SignInParameters
{
  login: string;
  password: string;
}

const SignInParameters_CT = new CRUDType<SignInParameters>()
    .add('login', FieldLoginOrEmail)
    .add('password', FieldLoginPassword)
;

export const Login = createPage(() => {

    let location = useLocation();
    let from = (location.state as any)?.from?.pathname || "/";
    let auth = useAuth();
    let navigate = useNavigate();
  
    const reducer = useCRUDReducer(SignInParameters_CT);
    const [showConfirmation, setShowConfirmation] = useState(false);
  
    const submit = () =>
    {
      let v = reducer.values;
      console.log("submit", v);
      
      auth.signin(v.login, v.password, (user?: any) => {
        console.info("Login result", user);
        if (user)
        {
          navigate(from, { replace: true });
          document.location.reload();
        }
        else
        {
            reducer.setGlobalError("Unable to log in"); // TODO
        }
      });

    };

    return <PageContent>
        <h1>Eye Surface Profiler</h1>
        <p>To log in to the Eye Surface Profiler, please enter your email address and password below.</p>

        <p>Forgot your password? Please <Link to="/login/reset">reset it here.</Link></p>

        <Stack>
            <CRUDForm<SignInParameters> 
                type={SignInParameters_CT}
                values={reducer}
                submit={submit}
            />

            <DialogActions>
                <FormButtons
                    values={reducer}
                    submit={submit}
                    submitLabel="Log in"
                />
            </DialogActions>
        </Stack>

    </PageContent>
    ;
}, "Sign In")

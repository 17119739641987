import { CRUDType } from ".";
import { CRUDField } from "./Field";
import { CRUDFieldContext, FieldFeedback } from "./Reducer";

export class CRUDValues<T>
{
    private _fields: {[key: string]: any} = {};
    private _feedback: {[key: string]: FieldFeedback} = {};
    private _globalError?: string = undefined;
    private _globalMessage?: string = undefined;
    private _wantsValidation: {[key: string]: boolean} = {};
    private readonly _context: CRUDFieldContext;

    public constructor(context: CRUDFieldContext)
    {
        this._context= context;
    }

    init(type: CRUDType<T>, values: any)
    {
        type.formFields.forEach((field) =>
        {
            this.set(field, CRUDValues.getFieldValue(values, field.fieldName));
        })
    }

    private static getFieldValue(values: any, fieldName: string)
    {
        let parts = fieldName.split(".");
        let value = values;
        for (let i = 0; i < parts.length && value; ++i)
        {
            value = value[parts[i]];
        }
        
        return value;
    }

    set(field: CRUDField, value: any)
    {
        this._fields[field.fieldName] = value;
        delete this._feedback[field.fieldName];
        this._wantsValidation[field.fieldName] = true;
    }

    notify(field?: CRUDField)
    {
        if (field)
        {
            if (field.options.onValueChange)
            {
                field.options.onValueChange(field, this.get(field), this._context);
            }
        }
        else
        {
            this._context.type.formFields.forEach((f) => this.notify(f));
        }
    }

    get(field: CRUDField): any
    {
        return this._fields[field.fieldName];
    }

    public get globalError() {return this._globalError;}
    public set globalError(val) {this._globalError = val;}
    
    public get globalMessage() {return this._globalMessage;}
    public set globalMessage(val) {this._globalMessage = val;}
    
    public wantsValidation(field: CRUDField) {return this._wantsValidation[field.fieldName] ?? false;}
    public clearWantsValidation()
    {
        this._wantsValidation = {};
    }

    setFeedback(field: CRUDField, value: FieldFeedback)
    {
        this._feedback[field.fieldName] = value;
    }

    getFeedback(field: CRUDField): FieldFeedback | undefined
    {
        return this._feedback[field.fieldName];
    }

    clone(): CRUDValues<T>
    {
        let c = new CRUDValues<T>(this._context);
        c._fields = Object.assign({}, this._fields);
        c._wantsValidation = this._wantsValidation;
        return c;
    }

    values(): {[key: string]: any}
    {
        return this._fields;
    }

    valuesSubmit(type: CRUDType<T>): {[key: string]: any}
    {
        let fields: {[key: string]: any} = {}
        type.formFields.forEach((field) =>
        {
            if (field.options.submit !== false && (field.options.enabled !== false))
            {
                fields[field.fieldName] = field.createSubmitValue(this._fields[field.fieldName]);
            }
        });
        return fields;
    }

    equals(other: CRUDValues<T>): boolean
    {
        for(let key in this._fields)
        {
            if (other._fields[key] !== this._fields[key])
                return false;
        }
        return true;
    }
}

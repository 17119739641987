import { Routes } from 'react-router-dom';
import { CLIENT_APP_ROUTES } from './ClientAppRoutes';
import { AuthProvider } from '../shared/components/Auth';
import { ConfirmProvider } from 'material-ui-confirm';
import { mapRoutes } from '../shared/util/Routing';
import { Layout } from '../shared/Layout';
import { DialogsProvider } from '../shared/Dialogs';

export function ClientApp() {
    return (
        <ConfirmProvider><DialogsProvider><AuthProvider>
            <Layout routes={CLIENT_APP_ROUTES}>
            <Routes>
                {
                    mapRoutes(CLIENT_APP_ROUTES)
                }
            </Routes>
            </Layout>
        </AuthProvider></DialogsProvider></ConfirmProvider>
    );
}

import { Button, Dialog, DialogContent, DialogTitle, Link } from '@mui/material';
import { Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CRUDEditor, CRUDService, CRUDTable } from '../../../shared/components/crud/index.d';
import { createPage } from '../../../shared/components/PageBase';
import { Group } from "../../../shared/components/Group";
import { useDialogs } from '../../../shared/Dialogs';
import { EPath } from '../../../shared/util/EPath';
import { API_PATH_ADMIN } from '../../Constants';
import { AdminUser } from '../../model/Admins';
import { AdminUserInvite, AdminUserInvite_CT, AdminUser_CT } from './AdminCRUD';


const AdminUser_CRUDService = new CRUDService(AdminUser_CT, new EPath(API_PATH_ADMIN + "/admin"));

export const AdminUsersPage = createPage(() => {
    // Creation mode
    let [create, setCreate] = useState(false);
    let dialogs = useDialogs();

    const inviteUser = (values: any) =>
    {
        console.info("invite admin", values);
        return AdminUser_CRUDService.callSelf("invite", values);
    };

    const showSuccess = () =>
    {
        dialogs.info({title: "Success", content: "The invitiation has beent sent."});
    };

    // TODO: type can be inferred from service
    return <Fragment>
        
        <Dialog onClose={()=>setCreate(false)} open={create}>
            <DialogTitle>Invite administrator</DialogTitle>
            <DialogContent>
                Please enter the email address of the person to invite as an administrator.
                <CRUDEditor<AdminUserInvite> 
                    type={AdminUserInvite_CT}
                    service={AdminUser_CRUDService}
                    onClose={() => setCreate(false)}
                    submitTitle="Invite"
                    submitAction={inviteUser}
                    onSuccess={showSuccess}
                />
            </DialogContent>
        </Dialog>

        <CRUDTable<AdminUser> 
            type={AdminUser_CT}
            service={AdminUser_CRUDService}
            title="Administrators"
            createTitle="Invite administrator"
            createAction={() => setCreate(true)}
        />
    </Fragment>;
}, "Administrators")

function GetResetUrl(token: string)
{
    return "/admin/login/reset/confirm?token=" + token; // TODO: encoding
}

export const AdminUserPage = createPage(() => {
    let { id } = useParams();
    const navigate = useNavigate();
    const dialogs = useDialogs();
    // TODO: merge with ClientUser
    
    const handleReset = () =>
    {

        dialogs.confirm({ title: 'Reset password?', content: 'This will not disable the current password until the user performs the reset.' })
            .then(() => 
            {
                AdminUser_CRUDService.call(id!, "auth/reset")
                    .then((response) =>
                        {
                            if (response.ok)
                            {
                                response.json().then(v =>
                                    {
                                        // TODO: make absolute
                                        let url = GetResetUrl(v.token);
                                        console.info("Password reset", v, url);
                                        dialogs.info({title: 'Password reset', 
                                        content: <div>
                                            <div>The password reset has been created</div>
                                            <br></br>
                                            <Link href={url}>Reset link</Link>
                                        </div>})
                                    });
                            }
                            else
                            {
                                dialogs.error({title: 'Password reset', content: 'An error occurred while creating the password reset.'})
                            }
                        }); // TODO: failure
            }, (x) => {})
        ;
    };

    const sendReset = () =>
    {

        dialogs.confirm({ title: 'Send password reset?', content: 'This will not disable the current password until the user performs the reset.' })
            .then(() => 
            {
                AdminUser_CRUDService.call(id!, "auth/reset-email")
                    .then((response) =>
                        {
                            if (response.ok)
                            {
                                dialogs.info({title: 'Password reset', content: 'The password reset email has been sent.'})
                            }
                            else
                            {
                                dialogs.error({title: 'Password reset', content: 'An error occurred while sending the password reset.'})
                            }
                        }); // TODO: failure
            }, (x) => {})
        ;
    };

    const handleRemove = () =>
    {
        dialogs.confirm({ title: 'Remove password?', content: 'This will make it impossible for the user to log in.' })
            .then(() => { 
                AdminUser_CRUDService.call(id!, "auth/remove")
                .then((response) =>
                        {
                            if (response.ok)
                            {
                                dialogs.info({title: 'Password remove', content: 'The password has been removed.'})
                            }
                            else
                            {
                                dialogs.error({title: 'Password remove', content: 'An error occurred while removing the password.'});
                            }
                        });
            }, (x) => {})
        ;
    };

    return <Fragment>
        <CRUDEditor<AdminUser>
            type={AdminUser_CT}
            service={AdminUser_CRUDService}
            id={id as string}
            onClose={()=>navigate(-1)}
        />
        
        <Group label="User functions">
            <Button onClick={handleRemove}>Remove Password</Button>
            <Button onClick={handleReset}>Reset Password</Button>
            <Button onClick={sendReset}>Email Password Reset</Button>
        </Group>

    </Fragment>;
}, "Administrator")

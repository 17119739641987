import { CRUDFieldOptions } from "../components/crud/Field";
import { CRUDType } from "../components/crud/CRUDType";
import { FieldEmail } from "../Fields";
import { EntityBase, EntityBase_CT } from "./EntityBase";
import { User } from "./User";

export enum GroupType
{
    Normal,
    Company,
    Supplier
}

export interface Group extends EntityBase
{
    readonly type: GroupType;
}

export const Company_CT = new CRUDType<Group>(EntityBase_CT)
    .merge("displayName", {
        placeholder: "Name of the company"
    })
;


export enum GroupRole
{
    Operator,
    Administrator
}

export interface UserWithId extends User
{
    readonly id: string;
}

export interface GroupWithId extends Group
{
    readonly id: string;
}

export interface GroupUser
{
    readonly user: UserWithId;
    readonly group: GroupWithId;
    readonly role: GroupRole;
}

export const FieldRole: CRUDFieldOptions =
{
    display: 'Role',
    placeholder: "The permissions for the practice",
    enumType: GroupRole
}

export const CompanyUser_CT = new CRUDType<GroupUser>()
    .makeId((item) => item.user.id) // Only showing one practice, so don't need that id. This way, can use it as path as well
    .add("user.displayName", {
        display: "User",
        placeholder: "The user's name"
    })
    /*.add("practice.displayName", {
        display: "Company",
        placeholder: "The practice's name"
    })*/
    .add("role", FieldRole)
;


export interface GroupUserInvite
{
    readonly email: string;
    readonly role: GroupRole;
};

export const CompanyUserInvite_CT = new CRUDType<GroupUserInvite>()
    .add("email", FieldEmail)
    .add("role", FieldRole)
;

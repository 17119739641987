import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CRUDEditor, CRUDForm, CRUDService, CRUDTable } from "../../shared/components/crud/index.d";
import { useCRUDReducer } from "../../shared/components/crud/Reducer";
import { FormButtons } from "../../shared/components/FormButtons";
import { createPage, PageHeader } from "../../shared/components/PageBase";
import { Switchable } from "../../shared/components/Switchable";
import { useDialogs } from "../../shared/Dialogs";
import { Group, GroupRole, GroupUser, GroupUserInvite, CompanyUserInvite_CT, CompanyUser_CT, Company_CT } from "../../shared/model/Group";
import { EPath } from "../../shared/util/EPath";
import { handleRequest } from "../../shared/util/ERequest";
import EagletCompanyIcon from '../../shared/images/Icon_Company.png';

const UserCompany_CRUDService = new CRUDService(Company_CT, new EPath("/api/user/company"));
const CompanyUser_CRUDService = new CRUDService(CompanyUser_CT, new EPath("/api/user/company/user"));
const CompanyUserInvite_CRUDService = new CRUDService(CompanyUserInvite_CT, new EPath("/api/user/company/user"));

export const MyCompanyPage = createPage(() => {
    let navigate = useNavigate();

    const reducer = useCRUDReducer(Company_CT);
    let dialogs = useDialogs();

    let [tab, setTab] = useState("no-company");
    let[ company, setCompany] = useState<GroupUser | undefined>(undefined);
    useEffect(() =>
    {
        handleRequest(UserCompany_CRUDService.getSelf(), (p) => {
            p.json().then((p:any) => 
            {
                console.log("company", p);
                setCompany(p); 
                setTab("loaded");
                reducer.setValues(Company_CT.newValues(reducer, () => p.group));
            }, () => {setCompany(undefined); setTab("no-company");});
        });
    }, []);

    const submitCompany = (isUpdate: boolean) =>
    {
      let v = reducer.values;
      console.log("submit", v);

      handleRequest(UserCompany_CRUDService.post(reducer.values),
        (success) =>
        {
            console.log("confirm success", success);

            reducer.onSubmitSuccessNoReset(isUpdate 
                ? "The company has been updated"
                : "The company has been created");
            if (!isUpdate)
            {
                document.location.reload();
            }
        },
        (err, json) =>
        {
            console.log("confirm error", err, json);
            reducer.onSubmitError(json);
        }
      );
    };

    let [addUserToCompany, setAddUserToCompany] = useState(false);
    const startAddUserToCompany = () =>
    {
        setAddUserToCompany(true);
    };

    const submitAddUserToCompany = (values: any) =>
    {
        console.info("invite user", values);
        return CompanyUserInvite_CRUDService.callSelf("invite", values);
    };

    const successAddUserToCompany = () =>
    {
        dialogs.info({title: "Success", content: "The invitiation has beent sent."});
    };
    
    let [selectUserInCompany, setSelectUserInCompany] = useState("");
    const startSelectUserInCompany = (item: any, id: any) =>
    {
        setSelectUserInCompany(id as string);
    };
    
    const submitSelectUserInCompany = (values: any) =>
    {
        console.info("update user", values);
        return CompanyUser_CRUDService.postToFunction(selectUserInCompany, values);
    };

    const successSelectUserInCompany = () =>
    {
        dialogs.info({title: "Success", content: "The user has been updated."});
        navigate(0); // Refresh table
    };

    return <Fragment>
        <Switchable index="no-company" value={tab}>
            <Stack>
                <PageHeader
                    title="My Company"
                    icon={EagletCompanyIcon}
                >
                    <p>You are not a member of a company. You can create a new company, or ask your company's administrator to add you. </p>
                </PageHeader>

                To create a new company, complete the form below.

                <CRUDForm<Group> 
                    type={Company_CT}
                    values={reducer}
                    submit={() => submitCompany(false)}
                />

                <DialogActions>
                    <FormButtons
                        values={reducer}
                        submit={() => submitCompany(false)}
                        submitLabel="Create company"
                    />
                </DialogActions>
            </Stack>

        </Switchable>

        <Switchable value="loaded" index={tab}>
            <Stack>
            <PageHeader
                    title="My Company"
                    icon={EagletCompanyIcon}
                >
                    <p>
                        {
                            company?.role === GroupRole.Administrator 
                            ? 
                            ("You manage this company.")
                            :
                            ("You are a member of this company.")
                        }
                    </p>
                </PageHeader>

            
                <div className="company-form-wrapper">
                    <Stack>
                        <CRUDForm<Group> 
                            type={Company_CT}
                            values={reducer}
                            submit={() => submitCompany(true)}
                        />

                        <DialogActions>
                            <FormButtons
                                values={reducer}
                                submit={() => submitCompany(true)}
                                submitLabel="Update company"
                            />
                      </DialogActions>
                    </Stack>
                </div>
            {
                company?.role === GroupRole.Administrator &&
                (<Fragment>
                    
                <Stack>
                    <CRUDTable<GroupUser> 
                        type={CompanyUser_CT}
                        service={CompanyUser_CRUDService}
                        title="Users in this company"
                        createAction={startAddUserToCompany}
                        createTitle="Invite user"
                        selectAction={startSelectUserInCompany}
                    />
                    
                    <DialogActions>
                        <Button
                            color='primary'
                            onClick={startAddUserToCompany}
                        >
                            Invite users
                        </Button>
                    </DialogActions>
                </Stack></Fragment>)
            }
            </Stack>
        </Switchable>

        <Dialog onClose={()=>setAddUserToCompany(false)} open={addUserToCompany}>
            <DialogTitle>Invite user</DialogTitle>
            <DialogContent>
                Please enter the email address of the person to invite to the company.
                <CRUDEditor<GroupUserInvite> 
                    type={CompanyUserInvite_CT}
                    service={CompanyUserInvite_CRUDService}
                    onClose={() => setAddUserToCompany(false)}
                    submitTitle="Invite"
                    submitAction={submitAddUserToCompany}
                    onSuccess={successAddUserToCompany}
                />
            </DialogContent>
        </Dialog>

        <Dialog onClose={()=>setSelectUserInCompany("")} open={!!selectUserInCompany}>
            <DialogTitle>Update user</DialogTitle>
            <DialogContent>
                <CRUDEditor<GroupUser> 
                    type={CompanyUser_CT.with("user.displayName", {readOnly: true, submit: false})}
                    service={CompanyUser_CRUDService}
                    id={selectUserInCompany}
                    onClose={() => setSelectUserInCompany("")}
                    submitTitle="Update"
                    submitAction={submitSelectUserInCompany}
                    onSuccess={successSelectUserInCompany}
                />
            </DialogContent>
        </Dialog>
    </Fragment>;
}, "My Company")

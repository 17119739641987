import { CRUDField, CRUDFieldOptions } from "../shared/components/crud/Field";
import { CRUDFieldContext, FieldFeedback } from "../shared/components/crud/Reducer";
import { FieldValidator, FieldValidatorMinLength, FieldValidatorNotEmpty, Validation, ValidationError, ValidationSuccess } from "../shared/components/crud/Validator";


class TPasswordValidator extends FieldValidatorMinLength
{
    constructor()
    {
        super(3);
    }
}
const PasswordValidator = new TPasswordValidator();

class TPasswordConfirmationValidator implements FieldValidator
{
    public validate(field: CRUDField, value: any, context: CRUDFieldContext, isInProgress: boolean, feedback?: FieldFeedback | undefined): Validation
    {
        let thisIndex = context.type.formFields.indexOf(field);
        let check = context.field(context.type.formFields[thisIndex - 1]).get();
        if (check != value)
        {
            return new ValidationError("Passwords do not match");
        }
        return PasswordValidator.validate(field, value, context, isInProgress, feedback);
    }
}
const PasswordConfirmationValidator = new TPasswordConfirmationValidator();

export const FieldCurrentPassword: CRUDFieldOptions =
{
    display: 'Existing password',
    type: 'password',
    validator: PasswordValidator
};

export const FieldLoginPassword: CRUDFieldOptions =
{
    display: 'Password',
    type: 'password',
    validator: new FieldValidatorNotEmpty()
};

export const FieldNewPassword: CRUDFieldOptions =
{
    display: 'New password',
    type: 'password',
    inputOptions: {autoComplete: 'new-password'},
    validator: PasswordValidator
}

export const FieldNewPasswordConfirm: CRUDFieldOptions =
{
    display: 'Confirm password',
    type: 'password',
    inputOptions: {autoComplete: 'new-password'},
    validator: PasswordConfirmationValidator
}



export class FieldValidatorEmail implements FieldValidator
{
    private _regexp: RegExp;

    public constructor()
    {
        this._regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    public validate(field: CRUDField, value: any, context: CRUDFieldContext, isInProgress: boolean, feedback?: FieldFeedback | undefined): Validation
    {
        
        if (!feedback && this._regexp.test(value))
        {
            return ValidationSuccess;
        }
        else
        {
            return new ValidationError(`${field.displayName} is not a valid email address`);
        }
    }
}

export const FieldEmail: CRUDFieldOptions =
{
    display: 'Email address',
    type: 'email',
    validator: new FieldValidatorEmail()
}

export const FieldLoginOrEmail: CRUDFieldOptions =
{
    display: 'Email address',
    type: 'text',
    placeholder: 'user@example.com',
    validator: new FieldValidatorNotEmpty()
}


export const FieldToken: CRUDFieldOptions =
{
    type: 'hidden'
}
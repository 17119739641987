import { Box, Button, DialogActions, FormControl, Input, InputLabel, Paper, Stack } from '@mui/material';
import { CRUDForm, CRUDService, CRUDType } from '../components/crud/index.d';
import { FeedbackStatus, useCRUDReducer } from '../components/crud/Reducer';
import { FieldCurrentPassword, FieldNewPassword, FieldNewPasswordConfirm } from '../Fields';
import { auth } from '../services/Auth.service';
import { EPath } from '../util/EPath';
import { handleRequest } from '../util/ERequest';

interface PasswordChangeFormData
{
  password: string;
  newPassword1: string;
  newPassword2: string;
}

const PasswordChangeData_CT = new CRUDType<PasswordChangeFormData>()
    .add('password', FieldCurrentPassword)
    .add('newPassword1', FieldNewPassword)
    .add('newPassword2', FieldNewPasswordConfirm)
;

interface ChangePasswordProperties
{
  autoFocus?: boolean;
}

export const ChangePassword = (props: ChangePasswordProperties) => 
{
  const reducer = useCRUDReducer(PasswordChangeData_CT, () =>
  {
      return  {
        password: '',
        newPassword1: '',
        newPassword2: ''
      }
  });
  reducer.autoFocus = props.autoFocus == undefined ? true : props.autoFocus;

  const submit = () =>
  {
    let v = reducer.values;
    console.log("Password change", v);
    
    // TODO: CT type
    const Auth_CRUDService = new CRUDService(PasswordChangeData_CT, auth.getServicePath());


    handleRequest(Auth_CRUDService.postToFunction("password/change", {
      existing: v.password,
      password: v.newPassword1
  }),
      (success) =>
      {
          console.log("confirm success", success);
          reducer.onSubmitSuccess("Your password has been changed");
      },
      (err, feedback) =>
      {
          console.log("confirm error", err, feedback);
          if (err.status == 403)
          {
            feedback = reducer.addFeedback(feedback, reducer.fieldExplicitMessage("password", "The password is incorrect"));
          }
          reducer.onSubmitError(feedback);
      }
    );
  };

  return <Box className='form-wrapper'>
    <Stack>
      <CRUDForm<PasswordChangeFormData> 
          type={PasswordChangeData_CT}
          values={reducer}
          submit={(e) => e.preventDefault()}
      />

      <DialogActions>
        <Button
            color='primary'
            onClick={submit}
            disabled={!reducer.validate().isAcceptable}
        >
            Update password
        </Button>
      </DialogActions>
    </Stack>
  </Box>;
};
import { Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab, Button } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { createPage } from "../../../shared/components/PageBase";
import { EPath } from "../../../shared/util/EPath";
import { ERequest, handleRequestTyped } from "../../../shared/util/ERequest";
import versionData from '../../../version.json';
import { addPathKeys } from "./DebugUtil";


export const DebugSettings = createPage(() => {

    let [settings, setSettings] = useState<{[key: string]: any}>(
        {
            "revision": versionData.rev,
            "build-date": versionData.date
        });

    const getServerSettings = (paths: string[]) =>
    {
        const get = (paths: string[], settingsCurrent: any) =>
        {
            if (paths.length == 0)
                return;

            let path = paths.shift()!;

            handleRequestTyped<any>(new ERequest(new EPath("/api/admin/debug").with(path)).exec(),
                (success) =>
                {
                    let loaded: {[key: string]: any} = {};
                    addPathKeys(loaded, success, path);
                    
                    let newSettings = Object.assign({...settingsCurrent}, loaded);
                    console.log("debug success", loaded, settingsCurrent, newSettings);
                    setSettings(newSettings);
                    get(paths, newSettings);
                },
                (err, json) =>
                {
                    console.log("debug error", err, json);
                    get(paths, settingsCurrent);
                }
            );
        };
        get(paths, settings);
    };

    useEffect(() =>
    {
        getServerSettings(["", "/version", "/services", "/smtp"]);
    }, []);


    return <Fragment><h1>Settings</h1>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Property
                            </TableCell>
                            <TableCell>
                                Value
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            Object.entries(settings).map(([key]) => <TableRow key={key}>
                                <TableCell>
                                    {key}
                                </TableCell>
                                <TableCell>
                                    {(settings as any)[key]}
                                </TableCell>
                            </TableRow>)
                        }
                    </TableBody>
                </Table>
        </Fragment>;
}, "Settings")

import { createPage } from "../../../shared/components/PageBase";
import { DirectConnectLensSetBase, DirectConnectSupplierBase, DirectConnectSuppliersBase } from "./DirectConnectSuppliersBase";

const OPTIONS = {
    readOnly: false,
    versionKey: "edit"
}
export const DirectConnectSuppliersEdit = createPage(() => DirectConnectSuppliersBase(OPTIONS), "Edit Suppliers")
export const DirectConnectSupplierEdit = createPage(() => DirectConnectSupplierBase(OPTIONS), "Edit Supplier")
export const DirectConnectLensSetEdit = createPage(() => DirectConnectLensSetBase(OPTIONS), "Edit Lens Set")

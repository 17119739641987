import { Button, DialogActions, Stack } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CRUDForm, CRUDService } from "../../shared/components/crud/index.d";
import { useCRUDReducer } from "../../shared/components/crud/Reducer";
import { FormButtons } from "../../shared/components/FormButtons";
import { createPage } from "../../shared/components/PageBase";
import { Switchable } from "../../shared/components/Switchable";
import { useDialogs } from "../../shared/Dialogs";
import { Group, GroupUser, CompanyUserInvite_CT, CompanyUser_CT, Company_CT } from "../../shared/model/Group";
import { EPath } from "../../shared/util/EPath";
import { handleRequest } from "../../shared/util/ERequest";

const UserCompany_CRUDService = new CRUDService(Company_CT, new EPath("/api/user/company"));
const CompanyUser_CRUDService = new CRUDService(CompanyUser_CT, new EPath("/api/user/company/user"));
const CompanyUserInvite_CRUDService = new CRUDService(CompanyUserInvite_CT, new EPath("/api/user/company/user"));

export const ConfirmCompanyPage = createPage(() => {
    
    const reducer = useCRUDReducer(Company_CT);

    let navigate = useNavigate();
    let token = new URLSearchParams(document.location.search).get('token');
    if (!token)
    {
        navigate('/');
    }
  
    const switchToCompanyView = () =>
    {
        navigate("/company", { replace: true });
    };

    let dialogs = useDialogs();

    let [sender, setSender] = useState(null);
    let[ company, setCompany] = useState<GroupUser | undefined>(undefined);

    useEffect(() =>
    {
        handleRequest(UserCompany_CRUDService.getFunction("invitation/" + token), (p) => {
            p.json().then((p:any) => 
            {
                console.log("company confirm", p);
                setCompany(p.company); 
                setSender(p.sender.displayName);
                reducer.setValues(Company_CT.newValues(reducer, () => p.company));
            }, 
            () => {switchToCompanyView(); });
        },
        () =>
        {
            switchToCompanyView();
        });
    }, []);

    const invitationReject = () =>
    {
        console.log("reject invitation");

        handleRequest(UserCompany_CRUDService.getFunction("invitation/" + token + "/reject"), 
            (p) => {switchToCompanyView();}, 
            () => {switchToCompanyView();}
        );
    };

    const invitationAccept = () =>
    {
        console.log("acceot invitation");

        handleRequest(UserCompany_CRUDService.getFunction("invitation/" + token + "/confirm"), 
            (p) => {switchToCompanyView();}, 
            () => {switchToCompanyView();}
        );
    };

    return <Fragment>
            <Stack>
            You have been invited to join this company by {sender}

            <CRUDForm<Group> 
                type={Company_CT}
                values={reducer}
            />
            <DialogActions>
                <Button
                    color="secondary"
                    type="reset"
                    onClick={invitationReject}
                >
                    Decline
                </Button>
                <Button
                    color="primary"
                    type="submit"
                    onClick={invitationAccept}
                >
                    Accept
                </Button>
            </DialogActions>

            </Stack>

    </Fragment>;
}, "My Company")

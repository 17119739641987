import { CommentsDisabledOutlined } from "@mui/icons-material";
import { DialogActions, Stack } from "@mui/material";
import { CRUDService, CRUDType } from ".";
import { handleRequest } from "../../util/ERequest";
import { CRUDButtons } from "./CRUDButtons";
import { CRUDForm } from "./CRUDForm";
import { CRUDReducer, useCRUDReducer, useCRUDReducerAsync } from "./Reducer";
import { Fragment } from "react";

interface CRUDEditorProperties<T>
{
    type: CRUDType<T>;
    service: CRUDService<T>;
    id?: string | undefined;
    onClose?: VoidFunction | undefined;
    onSuccess?: VoidFunction | undefined;
    submitTitle?: string;
    submitAction?: (values: any) => Promise<Response>;
    readOnly?: boolean;
}
export const CRUDEditor = <T extends unknown>(props : CRUDEditorProperties<T>) => 
{
    const isCreate = !props.id;
    const service = props.service;
    const submitAction = props.submitAction ?? ((values) => (isCreate ? service.create(values) : service.update(values)));
    const reducer = useCRUDReducerAsync(props.type, props.id ? () => props.service.get(props.id as string) : undefined);
    const readOnly = props.readOnly ?? false;

    const callbacks =
    {
        cancel: () =>
        {
            props.onClose?.();
        },

        reset: () =>
        {
            reducer.reset();
        },

        submit: () =>
        {
            handleRequest(submitAction(reducer.valuesSubmit),
                (success) =>
                {
                    props.onClose?.();
                    props.onSuccess?.();
                },
                (err, body) => reducer.onRequestError(err, body)
            );
        }
    }

    return <Stack>
        <CRUDForm<T> 
            type={props.type}
            values={reducer}
            submit={readOnly ? undefined : (e) => {e.preventDefault(); callbacks.submit();}}
        />

        {readOnly ? (<Fragment/>) : (
        <DialogActions>
            <CRUDButtons
                validity={reducer.validate()}
                hasChanges={reducer.hasChanges}
                submit={callbacks.submit}
                cancel={callbacks.cancel}
                reset={isCreate ? undefined : callbacks.reset}
                create={isCreate}
                submitTitle={props.submitTitle}
            />
        </DialogActions>)}
    </Stack>;
}

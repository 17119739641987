import { CRUDField } from "./Field";


export interface Sort 
{
    field: CRUDField | undefined;
    ascending: boolean;
}

export interface Paging 
{
    size: number;
    index: number;
    start?: string;
}

export interface Filter
{
    filter: string;
}

export interface Cursor
{
    sort: Sort;
    paging: Paging;
    filter?: Filter;
}

export interface CursorData<T>
{
    data: T[];
    matching: number;
    start: number;
}

export const EmptyCursorData =  {data: [], matching: 0, start: 0};


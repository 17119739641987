import { useEffect, useReducer, useState } from "react";

export enum TestStatus
{
    NotExecuted,
    Executing,
    Success,
    Failure
}

const STATUS_STRINGS: string[] =
[
    "Not Executed",
    "Executing",
    "Success",
    "Failure"
];

export class TestState
{
    public constructor(title: string)
    {
        this._title = title;
    }

    // TODO: proper id
    public get id(): string  {return this._title;}

    private _title!: string;
    public get title(): string  {return this._title;}

    private _status: TestStatus = TestStatus.NotExecuted;
    public get status(): TestStatus  {return this._status;}

    public get statusText(): string  {return STATUS_STRINGS[this._status];}

    public clone(status?: TestStatus): TestState
    {
        let c = new TestState(this.title);
        c._status = status ?? this.status;
        return c;
    }

    
}

abstract class TestCase
{
    protected abstract setStatus(status: TestStatus): void;

    public assert<T>(expectedValue: T): (actualValue: T) => void
    {
        let _this = this;
        return (actualValue: T) => 
        {
            console.info("ASSERT: ", actualValue, expectedValue, _this);
            if (actualValue === expectedValue)
            {
                _this.setStatus(TestStatus.Success);
            }
            else
            {
                _this.setStatus(TestStatus.Failure);
            }
        };
    }

    public assertResponseStatus(statusCode: number): (actualResponse: Response) => void
    {
        let _this = this;
        return (actualResponse: Response) => 
        {
            console.info("ASSERT RESPONSE: ", statusCode, actualResponse, _this);
            if (actualResponse.status === statusCode)
            {
                _this.setStatus(TestStatus.Success);
            }
            else
            {
                _this.setStatus(TestStatus.Failure);
            }
        };
    }

}

export type TestBody = (test: TestCase) => void;

class TestReducer
extends TestCase
{
    static reduce(state: TestState, action: any): TestState
    {
        console.log("reduce", state, action);
        switch(action.action)
        {
            case "executing":
                return state.clone(TestStatus.Executing);
            case "executed":
                // Status can only get worse, not better
                let newStatus = action.status;
                if (newStatus < state.status)
                {
                    newStatus = state.status;
                }
                return state.clone(newStatus);
        }
        return state;
    }

    private _state!: TestState;
    private _dispatch!: React.Dispatch<any>;

    init(state: TestState, dispatch: React.Dispatch<any>)
    {
        this._state = state;
        this._dispatch = dispatch;
    }

    override setStatus(status: TestStatus): void
    {
        this._dispatch({action: 'executed', status: status});
    }

}

export interface TestExecutor
{
    get id(): string;
    execute(): TestState;
}


export function useTest(title: string, test: TestBody): TestExecutor
{
    let reducer = new TestReducer();
    const [state, dispatch] = useReducer(TestReducer.reduce, new TestState(title));
    reducer.init(state, dispatch);

    let [executed, wantExecute] = useState(false);

    useEffect(() =>
    {
        if (executed)
        {
            dispatch({action: 'executing'});
            test(reducer);
        }
    // eslint-disable-next-line
    }, [executed]);

    let exec: TestExecutor = {

        get id(): string 
        {
            return state.id;
        },
    
        execute(): TestState 
        {
            if (!executed)
            {
                wantExecute(true);
            }
            return state;
        }
    
    };
    return exec;
}

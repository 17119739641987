import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { createPage } from "../shared/components/PageBase";
import { TEST_CASES } from "./TestCases";


export const TestPage = createPage(() => {
    let cases = TEST_CASES.map((c) => c());
    let { id } = useParams();
    if (id)
    {
        cases = cases.filter((c) => c.id === id);
    }

    return <Fragment>
        <table>
            <thead>
                <tr>
                    <th>Test Case</th>
                    <th>Status</th>
                </tr>
            </thead>

            <tbody>
                {
                    cases.map((exec) =>
                    {
                        let state = exec.execute();
                        return (<tr key={state.id}>
                            <td>
                                <a href={"/test/" + state.id}>
                                    {state.title}
                                </a>
                            </td>
                            <td>
                                {state.statusText}
                            </td>
                        </tr>);
                    })
                }
            </tbody>
        </table>
    </Fragment>;
}, "Test Page")

import { Button, ClickAwayListener, Link, Tooltip } from "@mui/material";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CRUDEditor, CRUDService, CRUDTable } from "../../../shared/components/crud/index.d";
import { Group } from "../../../shared/components/Group";
import { createPage } from "../../../shared/components/PageBase";
import { useDialogs } from "../../../shared/Dialogs";
import { EPath } from "../../../shared/util/EPath";
import { API_PATH_ADMIN } from "../../Constants";
import { User } from "../../model/Clients";
import { User_CT } from "./ClientsCRUD";

const User_CRUDService = new CRUDService(User_CT, new EPath(API_PATH_ADMIN + "/client/user"));

export const ClientUsers = createPage(() => {

    return <Fragment>
        <CRUDTable<User> 
            type={User_CT}
            service={User_CRUDService}
            title="Users"
        />
    </Fragment>;
}, "Users")

function GetResetUrl(token: string)
{
    return "/login/reset/confirm?token=" + token; // TODO: encoding
}

export const ClientUser = createPage(() => {
    let { id } = useParams();
    let navigate = useNavigate();
    const dialogs = useDialogs();

    const handleClose = () =>
    {
        navigate('..');
    };

    const handleReset = () =>
    {

        dialogs.confirm({ title: 'Reset password?', content: 'This will not disable the current password until the user performs the reset.' })
            .then(() => 
            {
                User_CRUDService.call(id!, "auth/reset")
                    .then((response) =>
                        {
                            if (response.ok)
                            {
                                response.json().then(v =>
                                    {
                                        // TODO: make absolute
                                        let url = GetResetUrl(v.token);
                                        console.info("Password reset", v, url);
                                        dialogs.info({title: 'Password reset', 
                                        content: <div>
                                            <div>The password reset has been created</div>
                                            <br></br>
                                            <Link href={url}>Reset link</Link>
                                        </div>})
                                    });
                            }
                            else
                            {
                                dialogs.error({title: 'Password reset', content: 'An error occurred while creating the password reset.'})
                            }
                        }); // TODO: failure
            }, (x) => {})
        ;
    };

    const sendReset = () =>
    {

        dialogs.confirm({ title: 'Send password reset?', content: 'This will not disable the current password until the user performs the reset.' })
            .then(() => 
            {
                User_CRUDService.call(id!, "auth/reset-email")
                    .then((response) =>
                        {
                            if (response.ok)
                            {
                                dialogs.info({title: 'Password reset', content: 'The password reset email has been sent.'})
                            }
                            else
                            {
                                dialogs.error({title: 'Password reset', content: 'An error occurred while sending the password reset.'})
                            }
                        }); // TODO: failure
            }, (x) => {})
        ;
    };

    const handleRemove = () =>
    {
        dialogs.confirm({ title: 'Remove password?', content: 'This will make it impossible for the user to log in.' })
            .then(() => { 
                User_CRUDService.call(id!, "auth/remove")
                .then((response) =>
                        {
                            if (response.ok)
                            {
                                dialogs.info({title: 'Password remove', content: 'The password has been removed.'})
                            }
                            else
                            {
                                dialogs.error({title: 'Password remove', content: 'An error occurred while removing the password.'});
                            }
                        });
            }, (x) => {})
        ;
    };

    return <Fragment>
        <CRUDEditor<User>
            type={User_CT}
            service={User_CRUDService}
            id={id as string}
            onClose={handleClose}
        />

        <Group label="User functions">
            <Button onClick={handleRemove}>Remove Password</Button>

            <Button onClick={handleReset}>Reset Password</Button>

            <Button onClick={sendReset}>Email Password Reset</Button>
        </Group>
    </Fragment>;
}, "User")

import { User } from "../../admin/model/Clients";
import { EPath } from "../util/EPath";
import { ERequest, handleRequest } from "../util/ERequest";

class AuthService
{
  private _path!: string;
  private _user! : Promise<User | undefined>;

  public init(path: string): void
  {
    this._path = path;

    // Fetch the existing user
    this.request(this._path, null);
  }

  public getServicePath(): EPath
  {
    return new EPath(this._path);
  }

  public async refreshUser(): Promise<User | undefined>
  {
    // Fetch the existing user
    return this.request(this._path, null);
  }

  public async getUser(): Promise<User | undefined>
  {
    return this._user;
  }

  private _isLoggedIn = false;
  public get isLoggedIn() : boolean
  {
    return this._isLoggedIn;
  }

  private request(request: RequestInfo, postData?: any) : Promise<User | undefined>
  {
      // TODO: proper error handling
      const requestOptions = postData ? {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(postData)
      } : {
        method: 'GET'
      };

    // TODO: use ERequest
      let _this = this;
      this._user = new Promise((resolve, reject) => {
        fetch(request, requestOptions)
          .then((result) => 
          {
            if (result.ok)
            {
              result.json().then((v) =>
              {
                console.info("auth success", v); 
                resolve(v);
                _this._isLoggedIn = true;
              });
            }
            else
            {
              console.info("auth failure"); 
              resolve(undefined);
            }
          })
          .catch(() =>
          {
            console.info("no user"); 
            resolve(undefined);
          });
        }); 
      return this._user;
  }

  public async signin(username: string, password: string, callback: (user?: any) => void) 
  {
      // Login
      // TODO: feedback failure to show login form again
      this.request(this._path + '/login', {username: username, password: password})
        .then((u) => callback(u) )
      ;
  }

  public async signout(callback: VoidFunction) 
  {
    let _this = this;
    const handler = () =>
    {
      _this._isLoggedIn = false;
      callback();
    };
    handleRequest(new ERequest(this.getServicePath().with("logout")).exec(), 
      (success) =>
      {
        handler();
      },
      (err, json) =>
      {
        handler();
      }
    );
  }
}

export const auth = new AuthService();

import { Checkbox, FormControl, FormControlLabel, Input, InputLabel, ListItem, ListItemText, MenuItem, Select, TextField } from "@mui/material";
import { Fragment, useState } from "react";
import { ImageControl } from "../ImageControl";
import { CRUDField } from "./Field";
import { CRUDFieldContext, CRUDReducerField } from "./Reducer";
import { ValidationUnchecked } from "./Validator";
import { DoubleArrayControl } from "../DoubleArrayControl";

interface FieldComponentProperties
{
    field: CRUDField;
    context: CRUDFieldContext;
    value: CRUDReducerField;
    readOnly: boolean;
    enabled?: boolean;
}

export const FieldComponent = (props: FieldComponentProperties) =>
{
    let [doValidate, setValidate] = useState(0);
    // Always validate to get validity of the whole form, but do not use the result unless the user did anything
    let validate = props.value.validate(props.context, doValidate == 1);
    if (doValidate == 0 || !props.value.wantsValidation())
    {
        validate = ValidationUnchecked;
    }

    const events =
    {
        onBlur(e: any): void
        {
            setValidate(2);
        },
        
        onChange(e: React.ChangeEvent<HTMLInputElement>): void
        {
            events.handleValueChange(e.target.value);
        },
        
        handleValueChange(value: any): void
        {
            setValidate(1);
            let mapped = props.field.ensureFieldMapped(value);
            props.value.onChange(mapped);
        }
    };
    let inputProps = {};
    if (props.readOnly)
        inputProps = {readOnly: true};

    if (!(props.enabled ?? true))
    {
        return <Fragment/>;
    }

    let initValue = props.field.ensureFieldMapped(props.value.get());
    if (initValue !== props.value.get())
    {
        props.value.set(initValue);
    }

    if (props.field.isEnum)
    {
        if (props.field.isArray)
        {
            return (<FormControl fullWidth>
                <Select 
                    name={props.field.fieldName}
                    id={props.field.fieldName}
                    variant='standard'
                    multiple={props.field.isArray}
                    label={props.field.displayName}
                    placeholder={props.field.options.placeholder}
                    value={initValue}
                    onChange={events.onChange}
                    onBlur={events.onBlur}
                    type={props.field.options.type}
                    error={validate.isError}
                    autoFocus={props.context.isAutoFocusField(props.field)}
                    inputProps={inputProps}
                    renderValue={(selected: any) => selected.map((key: any) => props.field.options.enumType[key]).join('; ')}
                    {...props.field.options.inputOptions}
                >
                    {
                        Object.keys(props.field.options.enumType).map((key) => !isNaN(parseInt(key)) && (
                            <MenuItem key={key} value={parseInt(key)}>
                                <Checkbox checked={props.value.get().indexOf(parseInt(key)) >= 0} />
                                <ListItemText primary={props.field.options.enumType[key]} />
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>);
        }
        else
        {
            return (<FormControl fullWidth>
                <Select 
                    name={props.field.fieldName}
                    id={props.field.fieldName}
                    variant='standard'
                    multiple={props.field.isArray}
                    label={props.field.displayName}
                    placeholder={props.field.options.placeholder}
                    value={initValue}
                    onChange={events.onChange}
                    onBlur={events.onBlur}
                    type={props.field.options.type}
                    error={validate.isError}
                    autoFocus={props.context.isAutoFocusField(props.field)}
                    inputProps={inputProps}
                    {...props.field.options.inputOptions}
                >
                    {
                        Object.keys(props.field.options.enumType).map((key) => !isNaN(parseInt(key)) && (
                            <MenuItem key={key} value={key}>{props.field.options.enumType[key]}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>);
        }
    }
    else if (props.field.options.type === "image")
    {
        return (<FormControl fullWidth>
            <ImageControl 
                name={props.field.fieldName}
                id={props.field.fieldName}
                label={props.field.displayName}
                value={props.value.get()}
                key={props.value.get()}
                onValueChange={events.handleValueChange}
                readOnly={props.readOnly}
            />
        </FormControl>);
    }
    else if (props.field.options.type === "boolean")
    {
        return (<FormControlLabel label={props.field.displayName}
        control={
            <Checkbox 
                name={props.field.fieldName}
                id={props.field.fieldName}
                key={props.value.get()}
                checked={initValue}
                onChange={(e) => events.handleValueChange(e.target.checked)}
                disabled={props.readOnly}
            />
        }/>);
    }
    else if (props.field.options.type === "double[]")
    {
        return(<FormControl fullWidth>
            <DoubleArrayControl 
                name={props.field.fieldName}
                id={props.field.fieldName}
                label={props.field.displayName}
                value={props.value.get()}
                key={props.value.get()}
                onValueChange={events.handleValueChange}
                readOnly={props.readOnly}
            />
        </FormControl>);
    }
    else if (props.field.options.type === "label")
    {
        console.log("label", props.value.get(), typeof(props.value.get()));
        return (<FormControl>{props.field.options.control!(props.field, props.value.get(), props.context)}</FormControl>);
    }
    else
    {
        return (<FormControl fullWidth>
            <TextField 
                name={props.field.fieldName}
                id={props.field.fieldName}
                variant='standard'
                label={props.field.displayName}
                placeholder={props.field.options.placeholder}
                value={props.value.get() ?? ''}
                onChange={events.onChange}
                onBlur={events.onBlur}
                type={props.field.options.type}
                error={validate.isError}
                helperText={validate.message}
                autoFocus={props.context.isAutoFocusField(props.field)}
                inputProps={inputProps}
                multiline={props.field.options.type === "multiline"}
                rows={10}
                {...props.field.options.inputOptions}
            />
        </FormControl>);
    }

}

import { RoutableEntry } from '../shared/util/Routing';
import { Home } from './pages/Home';
import { Login } from './pages/Login';
import { ResetPasswordUser } from '../shared/pages/ResetPassword';
import { ESPLaunch } from './pages/ESPLaunch';
import { RequestSignUp } from './pages/signup/RequestSignUp';
import { UserSignUp } from './pages/signup/UserSignUp';
import { MyCompanyPage } from './pages/MyCompanyPage';
import { ClientLanding } from './pages/ClientLanding';
import { MyAccountPage } from '../shared/pages/MyAccountPage';
import { ConfirmCompanyPage } from './pages/ConfirmCompanyPage';
import { RequestPasswordReset } from './pages/RequestPasswordReset';
import { MyDCGroupsPage } from './pages/MyDCGroupsPage';

export const CLIENT_APP_ROUTES: readonly RoutableEntry[] =
[
    {
        path: "/",
        element: ClientLanding,
        auth: false
    },
    {
        path: "/signup",
        element: RequestSignUp,
        auth: false,
        children: [{
            path: 'confirm',
            element: UserSignUp
        }]
    },
    {
        path: "/login",
        element: Login,
        auth: false
    },
    {
        path: "/login/reset",
        element: RequestPasswordReset,
        auth: false
    },
    {
        path: "/login/reset/confirm",
        element: ResetPasswordUser,
        auth: false
    },
    {
        path: "/esp/launch",
        element: ESPLaunch
    },
    {
        path: "/home",
        element: Home,
        menu: 'primary'
    },
    {
        path: "/account",
        element: MyAccountPage,
        menu: 'primary'
    },
    {
        path: "/company",
        element: MyCompanyPage,
        menu: 'primary'
    },
    {
        path: "/company/confirm",
        element: ConfirmCompanyPage
    }/*,
    {
        path: "/dcgroup",
        element: MyDCGroupsPage,
        menu: 'primary'
    }*/
];


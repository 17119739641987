import { Fragment, useEffect, useState } from "react";
import { createPage } from "../../../shared/components/PageBase";
import { DirectConnectLensSetBase, DirectConnectSupplierBase, DirectConnectSuppliersBase } from "./DirectConnectSuppliersBase";
import { useStateAsync } from "../../../shared/util/StateHelpers";
import { ERequest, handleRequestTyped } from "../../../shared/util/ERequest";
import { EPath } from "../../../shared/util/EPath";
import { Button } from "@mui/material";
import { useDialogs } from "../../../shared/Dialogs";

const OPTIONS = {
    readOnly: true,
    versionKey: "published"
}

interface VersionInfo
{
    readonly created: string;
}

interface VersionHistoryInfo
{
    readonly inProgress?: VersionInfo;
    readonly published?: VersionInfo;
    readonly hasChanges: boolean;
}

export const DirectConnectSuppliersPublished = createPage(() => 
{
    let[versionInfo, setVersionInfo] = useState({hasChanges: false} as VersionHistoryInfo);
    const dialogs = useDialogs();

    useEffect(() =>
    {
        handleRequestTyped<any>( new ERequest(new EPath("/api/admin/share/services/directconnect/published/changes")).exec(),
            (success) =>
            {
                setVersionInfo(success);
            }
            // TODO: error
        );
    }, []);

    const checkPublish = () =>
    {
        dialogs.confirm({ title: 'Publish changes?', content: 'Please make sure all the changes are correct before publishing.' })
        .then(() => 
        {
            console.log("PUBLISH"); 
            handleRequestTyped<VersionHistoryInfo>( new ERequest(new EPath("/api/admin/share/services/directconnect/published/publish")).method("POST").exec(),
                (success) =>
                {
                    dialogs
                        .info({title: "Success", content: "The changes have been published."})
                        .then(() => document.location.reload());
                },
                (errors, body) =>
                {
                    dialogs
                        .info({title: "Error", content: "There was an error publishing the changes."})
                        .then(() => document.location.reload());
                }
            );

        }, (x) => {});
    };

    // TODO: header layour
    return (<Fragment>
        <div>
            <h1>Published Suppliers</h1>
            <div> Current version: {versionInfo.inProgress?.created ?? "None"} </div>
            <div> Last published: {versionInfo.published?.created ?? "Never"} </div>
            <div> Unpublished changes: {versionInfo.hasChanges ? "Yes" : "No"}</div>
            {versionInfo.hasChanges ? (<Button onClick={checkPublish}>Publish Changes</Button>) : (<Fragment/>)}
        </div>
        {    
            DirectConnectSuppliersBase(OPTIONS)
        }
    </Fragment>);
}, "Published Suppliers")

export const DirectConnectSupplierPublished = createPage(() => DirectConnectSupplierBase(OPTIONS), "Published Supplier")
export const DirectConnectLensSetPublished = createPage(() => DirectConnectLensSetBase(OPTIONS), "Published Lens Set")


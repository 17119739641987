import { CRUDType } from ".";
import { ERequest } from "../../util/ERequest";
import { EQuery, EPath } from "../../util/EPath";
import { Cursor, CursorData } from "./Cursor";
import { UnparsedSyntheticReference } from "typescript";

export class CRUDService<T>
{
    private _type: CRUDType<T>;
    private _path: EPath;

    public constructor(type: CRUDType<T>, path: EPath)
    {
        this._type = type;
        this._path = path;
    }

    public bind(parameter: any): CRUDService<T>
    {
        return new CRUDService<T>(this._type, this._path.bind(parameter));
    }

    public appendPath(path: any): CRUDService<T>
    {
        return new CRUDService<T>(this._type, new EPath(this._path.path + path));
    }

    private withCursorParameters(cursor?: Cursor): EQuery
    {
        let result = new EQuery(this._path);
        if (cursor?.sort?.field)
        {
            if (!cursor.sort.ascending)
            {
                result = result.withQuery("sort", "-" + cursor.sort.field.fieldName);
            }
            else
            {
                result = result.withQuery("sort", cursor.sort.field.fieldName);
            }
        }

        if (cursor?.paging)
        {
            result = result
                .withQuery("pageSize", cursor.paging.size)
                .withQuery("pageStart", cursor.paging.index)
            ;
        }

        if (cursor?.filter && cursor.filter.filter !== "")
        {
            result = result
                .withQuery("filter", cursor.filter.filter) // TODO: encoding
        }

        return result;
    }

    private async request(url: EQuery): Promise<Response>
    {
        return fetch(url.toRequest());
    }

    public async list(cursor?: Cursor): Promise<CursorData<T>>
    {
        let query = this.withCursorParameters(cursor);
        console.info("listing", query.toRequest());
        return await new ERequest(query)
            .exec()
            .then((value) => value.json().then((j) => j as CursorData<T>))
        ;
    }

    // TODO: this probably doesn't work as expected
    public async get(id: string): Promise<T>
    {
        console.info("getting", this)
        const response = await fetch(this._path.path + '/' + id);
        return await response.json();
    }

    public async getSelf(): Promise<Response>
    {
        console.info("getting", this)
        return fetch(this._path.path);
    }

    public async getFunction(id: string): Promise<Response>
    {
        let url = this._path.with(id);
        console.info("getting", this, url);
        return fetch(url.path);
    }

    public async create(values: any): Promise<Response>
    {
        console.log("Create", values, values['id'], this);
        let path = this._path.with(values['id']);

        return new ERequest(path)
            .method('PUT')
            .bodyJSON(values)
            .exec()
        ;
    }

    public async update(values: any): Promise<Response>
    {
        console.log("Update", values, values['id'], this);
        // TODO: remove dependency on id field name
        let path = this._path.with(values['id']);

        return new ERequest(path)
            .method('POST')
            .bodyJSON(values)
            .exec()
        ;
    }

    public async delete(ids: readonly string[]): Promise<Response[]>
    {
        let responses = [];

        for (const id of ids)
        {
            let url = this._path.path + '/' + id;
            console.info("delete", url);
            const requestOptions = {
                method: 'DELETE'
            };
            const response = await fetch(url, requestOptions);
            responses.push(response);
        }

        return responses;
    }

    private static callOptions(p?: any | undefined)
    {
        if (p)
        {
            return {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(p)
            };
        }
        else
        {
            return {
                method: 'POST'
            };
        }
    }

    public async call(id: string, func: string, p?: any | undefined): Promise<Response>
    {
        let url = this._path.path + '/' + id + '/' + func;
        console.info("call", url);
        return fetch(url, CRUDService.callOptions(p));
    }
    
    public async callSelf(func: string, p?: any | undefined): Promise<Response>
    {
        let url = this._path.path + '/' + func;
        console.info("call", url);
        return fetch(url, CRUDService.callOptions(p));
    }

    public postToFunction(func: string, body?: any | undefined): Promise<Response>
    {
        let request = new ERequest(this._path.with(func));
        if (body)
        {
            request.bodyJSON(body);
        }
        request.method("POST");
        return request.exec();
    }

    public post( body?: any | undefined): Promise<Response>
    {
        let request = new ERequest(this._path);
        if (body)
        {
            request.bodyJSON(body);
        }
        request.method("POST");
        return request.exec();
    }
}

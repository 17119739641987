import { Fragment } from "react";
import { CRUDService, CRUDTable, CRUDType } from "../../../shared/components/crud/index.d";
import { createPage } from "../../../shared/components/PageBase";
import { EPath } from "../../../shared/util/EPath";
import { API_PATH_ADMIN } from "../../Constants";

interface AnalyticsEntry
{
}

export const AnalyticsEntry_CT = new CRUDType<AnalyticsEntry>()
    .add("path", {display: "Path"})
    .add("user.displayName", {display: "User"})
    .add("user.email", {display: "User"})
    .add("timestamp", {display: "Time"})
    .add("computer", {display: "Computer"})
    .add("address", {display: "Address"})
    .add("environment_App", {display: "ESP Version"})
    .add("environment_Windows", {display: "Windows Version"})
    .add("environment_Driver", {display: "Driver Version"})
    .add("environment_Screen", {display: "Screen"})
    .add("device_Device", {display: "Device"})
    .add("device_Camera", {display: "Camera"})
    .add("device_Model", {display: "Model"})

;
const Analytics_Service = new CRUDService(AnalyticsEntry_CT, new EPath(API_PATH_ADMIN + "/analytics"));

export const Analytics = createPage(() => {

    return <Fragment>
        <CRUDTable<AnalyticsEntry> 
            type={AnalyticsEntry_CT}
            service={Analytics_Service}
            title="Recent events"
            sort={{field: AnalyticsEntry_CT.field("timestamp"), ascending: false}}
        />
    </Fragment>;
}, "Analytics")

import { Box, FormHelperText, FormLabel } from "@mui/material";
import { Fragment } from "react";
import { CRUDType } from ".";
import { CRUDReducer } from "./Reducer";


interface CRUDFormProperties<T>
{
    type: CRUDType<T>;
    values: CRUDReducer<T>;
    submit?: (event: React.FormEvent<HTMLFormElement>) => void;
}

export const CRUDForm = function <T extends unknown>({type, values, submit} : CRUDFormProperties<T>) 
{
    const readOnly = !submit;
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) =>
    {
        event.preventDefault();
        if (values.validate().isAcceptable)
        {
            submit?.(event);
        }
    };
    return <Fragment>
        <form 
            className="form" 
            onSubmit={handleSubmit}
            
        >
            {
                // Global message
                values.hasGlobalMessage && (<FormHelperText error={false}>{values.globalMessageText}</FormHelperText>)
            }   
            {
                // Global error
                values.hasGlobalError && (<FormHelperText error={true}>{values.globalErrorText}</FormHelperText>)
            }   
            {
                // Fields
                type.formFields.map((field) => field.createInput(values, values.field(field), readOnly))
                
            }
            {
                // Submit
                !readOnly && (<input type="submit" style={{display: 'none'}}></input>)

            }
        </form>
    </Fragment>;
};


export interface EntityBase {
    readonly id: string;
    readonly displayName: string;
}

export interface UserBase  extends EntityBase {
    readonly login: string;
    readonly email: string;
}

export interface User extends UserBase
{

}

export enum GroupType
{
    Normal,
    Company,
    Supplier
}

export interface Group extends EntityBase
{
    readonly type: GroupType;
}
import React from 'react';
import {
    Navigate,
    useLocation
} from "react-router-dom";

import { auth } from "../services/Auth.service";

interface AuthContextType {
    user: any;
    refreshUser: () => Promise<void>;
    signin: (username: string, password: string, callback: (user: any)=> void) => void;
    signout: (callback: VoidFunction) => void;
}

const AuthContext = React.createContext<AuthContextType>(null!);
 
export function AuthProvider({ children }: { children: React.ReactNode }) {

    let [user, setUser] = React.useState<any>({});

    auth.getUser().then(setUser);

    let refreshUser = () => {
        return auth.refreshUser().then(setUser);
    };
    
    let signin = (username: string, password: string, callback: (user: any)=> void) => {
        return auth.signin(username, password, (u) => {
            setUser(u);
            callback(u);
        });
    };

    let signout = (callback: VoidFunction) => {
        return auth.signout(() => {
            setUser(null);
            callback();
        });
    };

    let value = { user, refreshUser, signin, signout };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return React.useContext(AuthContext);
}

export function RequireAuth({ children }: { children: JSX.Element }) {
    let auth = useAuth();
    let location = useLocation();
  
    if (!auth.user) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  
    return children;
}

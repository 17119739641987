import { Dialog,  DialogContent, DialogTitle } from "@mui/material";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CRUDEditor, CRUDService, CRUDTable, CRUDType } from "../../../shared/components/crud/index.d";
import { Group, GroupType } from "../../model/Clients";
import { createPage } from "../../../shared/components/PageBase";
import { Group_CT } from "./ClientsCRUD";
import { EPath } from "../../../shared/util/EPath";
import { API_PATH_ADMIN } from "../../Constants";
import { FieldRole, GroupRole, GroupUser, CompanyUser_CT } from "../../../shared/model/Group";
import { FieldLoginOrEmail } from "../../../shared/Fields";
import { useDialogs } from "../../../shared/Dialogs";



export interface GroupUserAdd
{
    readonly user: string;
    readonly role: GroupRole;
};

export const GroupUserAdd_CT = new CRUDType<GroupUserAdd>()
    .add("user", FieldLoginOrEmail)
    .add("role", FieldRole)
;

const Group_CRUDService = new CRUDService(Group_CT, new EPath(API_PATH_ADMIN + "/client/group"));

const ClientGroupsBase = (type: GroupType) => {
    return <Fragment>
        <CRUDTable<Group> 
            type={Group_CT}
            service={Group_CRUDService.appendPath("?type=" + type)}
            title="Groups"
            createTitle="Create group"
        />
    </Fragment>;
};


const ClientGroupBase = () => {
    let { id } = useParams();
    // TODO: id handling
    const CompanyUser_CRUDService = new CRUDService(CompanyUser_CT, new EPath(API_PATH_ADMIN + "/client/group/" + id + "/user"));
    const CompanyUserAdd_CRUDService = new CRUDService(GroupUserAdd_CT, new EPath(API_PATH_ADMIN + "/client/group/" + id + "/user"));

    let navigate = useNavigate();
    let dialogs = useDialogs();

    const handleClose = () =>
    {
        navigate(-1);
    };

    
    let [addUserToCompany, setAddUserToCompany] = useState(false);
    const startAddUserToCompany = () =>
    {
        setAddUserToCompany(true);
    };

    const submitAddUserToCompany = (values: any) =>
    {
        console.info("add user", values);
        return CompanyUserAdd_CRUDService.post(values);
    };

    const successAddUserToCompany = () =>
    {
        dialogs.info({title: "Success", content: "The user has beent added."});
        navigate(0); // Refresh table
    };
    
    let [selectUserInCompany, setSelectUserInCompany] = useState("");
    const startSelectUserInCompany = (item: any, id: any) =>
    {
        console.log("select", id);
        setSelectUserInCompany(id as string);
    };
    
    const submitSelectUserInCompany = (values: any) =>
    {
        console.info("update user", values);
        return CompanyUser_CRUDService.postToFunction(selectUserInCompany, values);
    };

    const successSelectUserInCompany = () =>
    {
        dialogs.info({title: "Success", content: "The user has been updated."});
        navigate(0); // Refresh table
    };

    return <Fragment>
        <CRUDEditor<Group>
            type={Group_CT}
            service={Group_CRUDService}
            id={id as string}
            onClose={handleClose}
        />

        <CRUDTable<GroupUser> 
            type={CompanyUser_CT}
            service={CompanyUser_CRUDService.bind(id)}
            title="Users in this group"
            createAction={startAddUserToCompany}
            createTitle="Add user to group"
            selectAction={startSelectUserInCompany}
        />

        <Dialog onClose={()=>setAddUserToCompany(false)} open={addUserToCompany}>
            <DialogTitle>Invite user</DialogTitle>
            <DialogContent>
                Please enter the email address of the person to invite to the company.
                <CRUDEditor<GroupUserAdd> 
                    type={GroupUserAdd_CT}
                    service={CompanyUserAdd_CRUDService}
                    onClose={() => setAddUserToCompany(false)}
                    submitTitle="Add"
                    submitAction={submitAddUserToCompany}
                    onSuccess={successAddUserToCompany}
                />
            </DialogContent>
        </Dialog>

        <Dialog onClose={()=>setSelectUserInCompany("")} open={!!selectUserInCompany}>
            <DialogTitle>Update user</DialogTitle>
            <DialogContent>
                <CRUDEditor<GroupUser> 
                    type={CompanyUser_CT.with("user.displayName", {readOnly: true, submit: false})}
                    service={CompanyUser_CRUDService}
                    id={selectUserInCompany}
                    onClose={() => setSelectUserInCompany("")}
                    submitTitle="Update"
                    submitAction={submitSelectUserInCompany}
                    onSuccess={successSelectUserInCompany}
                />
            </DialogContent>
        </Dialog>

    </Fragment>;
};

export const ClientCompanies = createPage(() => ClientGroupsBase(GroupType.Company), "Companies");
export const ClientCompany = createPage(() => ClientGroupBase(), "Company");

export const ClientSuppliers = createPage(() => ClientGroupsBase(GroupType.Supplier), "Suppliers");
export const ClientSupplier = createPage(() => ClientGroupBase(), "Supplier");

import React, { Component, Fragment, FunctionComponent } from 'react';

export interface Page {
    readonly title: string;
}

interface ComponentMerger<P> extends FunctionComponent<P>, Page
{
    title: string;
}
export type FCPage<P = {}> = ComponentMerger<P>;

export function createPage(page: () => void, title: string): FCPage
{
    const t: FCPage = page as any;
    t.title = title;
    return t;
}

export abstract class PageBase<P, S> 
extends React.Component<P, S>
implements Page 
{
    abstract readonly title: string;
}



interface PageContentProperties
{
    children: React.ReactNode
}

export const PageContent = ({children} : PageContentProperties) => 
{
    return (<div className="page">
        <div className="content">{children}</div>
        </div>
    );
}


interface PageHeaderProps {
    children?: React.ReactNode;
    title: string;
    icon: string;
  }
  
export function PageHeader(props: PageHeaderProps) {
    return (<Fragment>
        <div className='page-header'>
            <div className='page-header-1'>
            <div className='page-header-title'>
                <h1>{props.title}</h1>
            </div>
            <div className='page-header-text'>
                {props.children}
            </div>
            </div>
            <div className='page-header-icon'>
                <img className='icon' src={props.icon}/>
            </div>
        </div>
    </Fragment>
    );
  }
  